// In the future, probably can do some i18n here

import { EMAIL_VFN, MOBILE_VFN } from '~/lib/errors/settings'

const MOBILE_VFN_HUMAN_READABLE_ERROR = {
  [MOBILE_VFN.sameAsExisting]: 'This number is already saved on your account.',
  [MOBILE_VFN.unknown]:
    'Error sending verification SMS. Please try again later.',
  [MOBILE_VFN.userNotFound]:
    'Something went wrong. Please try refreshing the page.',
}

// If the error message is not found in the mapping, return the original message
export const getMobileVfnHumanReadableError = (message: string) => {
  return MOBILE_VFN_HUMAN_READABLE_ERROR[message] ?? message
}

const EMAIL_VFN_HUMAN_READABLE_ERROR = {
  [EMAIL_VFN.sameAsExisting]: 'This email is already saved on your account.',
  [EMAIL_VFN.unknown]:
    'Error sending verification email. Please try again later.',
  [EMAIL_VFN.userNotFound]:
    'Something went wrong. Please try refreshing the page.',
}

// If the error message is not found in the mapping, return the original message
export const getEmailVfnHumanReadableError = (message: string) => {
  return EMAIL_VFN_HUMAN_READABLE_ERROR[message] ?? message
}
