import { REDIRECT_URL_KEY } from '@activesg/constants';
import { getBaseUrl } from './getBaseUrl.js';
export const appendWithRedirect = (url, redirectUrl) => {
    if (!redirectUrl) {
        return url;
    }
    return `${url}?redirectUrl=${redirectUrl}`;
};
export const getRedirectUrl = (query) => {
    if (!query[REDIRECT_URL_KEY]) {
        return undefined;
    }
    return decodeURIComponent(String(query[REDIRECT_URL_KEY]));
};
export const isRelativeUrl = (url) => {
    const baseUrl = getBaseUrl();
    try {
        const normalizedUrl = new URL(url, baseUrl);
        return new URL(baseUrl).origin === normalizedUrl.origin;
    }
    catch (_) {
        return false;
    }
};
