import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { Badge } from './Badge';
// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);
const variantSubtle = definePartsStyle((props) => {
    return {
        container: {
            ...Badge.variants.subtle(props),
        },
    };
});
const variantSolid = definePartsStyle((props) => {
    return {
        container: {
            ...Badge.variants.solid(props),
        },
    };
});
const variants = {
    subtle: variantSubtle,
    solid: variantSolid,
};
export const Tag = defineMultiStyleConfig({ variants });
