import bigInt from 'big-integer';
import { divideAndRoundOff } from './big-int/index.js';
/* When calculating GST amount, we typically work backwards from the total amount including GST.
  As such, to calculate the pre-GST amount, we divide the total amount by 1 + GST rate.
  To do this in bigInt, the divisor is 1 + GST rate, and the multiplier is 100.
 */
const getGstRate = (year) => {
    const divisor = year >= 2024 ? bigInt(109) : bigInt(108);
    const multiplier = bigInt(100);
    return { divisor, multiplier };
};
export const getPreGstAmount = (totalAmount, year) => {
    const { divisor, multiplier } = getGstRate(year);
    return divideAndRoundOff({
        num: totalAmount,
        divisor,
        multiplier,
    });
};
