import { useBreakpointValue as useChakraBreakpointValue, } from '@chakra-ui/react';
/**
 * Default useBreakpointValue to have `ssr: false` to prevent flash of
 * incorrect media query values when used.
 *
 * See https://chakra-ui.com/docs/hooks/use-breakpoint-value#usage
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useBreakpointValue = (value, args) => {
    return useChakraBreakpointValue(value, {
        ssr: false,
        ...args,
    });
};
