import { cardAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
const cardParts = parts.extend('root');
// eslint-disable-next-line @typescript-eslint/unbound-method
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardParts.keys);
export const cardTheme = defineMultiStyleConfig({
    defaultProps: {
        variant: 'outline',
        size: 'md',
    },
    baseStyle: {
        container: {
            boxSizing: 'border-box',
            my: '1px',
            px: `1px`,
            '@media(hover: hover)': {
                _hover: {
                    borderWidth: '1px',
                    borderColor: 'base.divider.strong',
                    bg: 'interaction.tinted.sub.hover',
                    transitionProperty: 'border-color, background',
                    transitionTimingFunction: 'ease-in',
                    transitionDuration: '100ms',
                },
                _active: {
                    m: '0px',
                    p: '0px',
                    borderWidth: '2px',
                    borderColor: 'interaction.sub.active',
                    bg: 'interaction.tinted.sub.hover',
                    transitionProperty: 'border-color, background',
                    transitionTimingFunction: 'ease-in',
                    transitionDuration: '100ms',
                },
            },
            _selected: {
                m: '0px',
                p: '0px',
                borderWidth: '2px',
                borderColor: 'interaction.sub.active',
                bg: 'interaction.tinted.sub.hover',
                transition: 'ease-in 100ms',
            },
            _checked: {
                m: '0px',
                p: '0px',
                borderWidth: '2px',
                borderColor: 'interaction.sub.active',
                bg: 'interaction.tinted.sub.hover',
                transition: 'ease-in 100ms',
            },
        },
        header: {
            p: '1rem',
            pb: 0,
        },
        body: {
            p: '1rem',
            w: 'full',
        },
    },
});
