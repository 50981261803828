import { ProgrammeBookingStatus, ProgrammeSessionBookingStatus, } from '@activesg/db';
import { dayjs } from './dayjs/index.js';
export const getStartAndEndDateTimeForSession = (day, startTimeInMs, endTimeInMs) => {
    const startOfDay = dayjs(day).tz().startOf('day');
    return {
        startDateTime: startOfDay.add(startTimeInMs, 'ms').toDate(),
        endDateTime: startOfDay.add(endTimeInMs, 'ms').toDate(),
    };
};
export const PROGRAMME_BOOKING_STATUS_TO_LABEL = {
    CANCELLED: 'Withdrawn',
    CONFIRMED: 'Confirmed',
    PENDING: 'Pending',
};
export const hasAnySessionElapsed = (sessions) => {
    return sessions.some((session) => {
        return dayjs(session.startDateTime).tz().isBefore(dayjs().tz());
    });
};
export function mapProgrammeBookingStatusToSessionStatus(status, isRefund) {
    switch (status) {
        case ProgrammeBookingStatus.CANCELLED: {
            return isRefund
                ? ProgrammeSessionBookingStatus.CANCELLED_WITH_REFUND
                : ProgrammeSessionBookingStatus.CANCELLED_WITHOUT_REFUND;
        }
        case ProgrammeBookingStatus.PENDING: {
            return ProgrammeSessionBookingStatus.PENDING;
        }
        case ProgrammeBookingStatus.CONFIRMED: {
            return ProgrammeSessionBookingStatus.CONFIRMED;
        }
    }
}
