import { type SVGProps } from 'react'

import {
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Button, useIsMobile } from '@opengovsg/design-system-react'

import { useUserOnboarding } from '../UserOnboardingModalContext'

const SuccessCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={80}
    width={80}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#D7F4E3" height={80} rx={40} width={80} />
    <path
      d="M36.248 47.528a1.429 1.429 0 0 1-2.02 0l-5.147-5.147a2.38 2.38 0 0 0-3.367 3.367l8.514 8.513a1.429 1.429 0 0 0 2.02 0l20.419-20.418a2.38 2.38 0 1 0-3.367-3.367L36.248 47.528Z"
      fill="#16723B"
    />
  </svg>
)

export const SuccessContent = (): JSX.Element | null => {
  const isMobile = useIsMobile()
  const { onClose } = useUserOnboarding()

  return (
    <>
      <ModalHeader>
        <Stack spacing="2.25rem">
          <Icon alignSelf="center" as={SuccessCircleIcon} h="5rem" w="5rem" />
          <Text pr="5rem">Mobile number verified</Text>
        </Stack>
      </ModalHeader>
      <ModalBody flex={1} whiteSpace="pre-wrap">
        Your mobile number has been saved to your profile. You can still change
        it anytime.
      </ModalBody>
      <ModalFooter>
        <Button isFullWidth={isMobile} variant="outline" onClick={onClose}>
          Done
        </Button>
      </ModalFooter>
    </>
  )
}
