import { forwardRef, useRef, } from 'react';
import { assignRef, useMergeRefs } from '@chakra-ui/react';
export const FileButton = forwardRef(({ onChange, children, multiple, accept, name, form, resetRef, disabled, capture, inputProps, value, ...others }, ref) => {
    const inputRef = useRef();
    const onClick = () => {
        !disabled && inputRef.current?.click();
    };
    const handleChange = (event) => {
        if (multiple) {
            const nextFiles = Array.from(event.currentTarget.files ?? []);
            if (others.append) {
                onChange([...value, ...nextFiles]);
            }
            else {
                onChange(Array.from(event.currentTarget.files ?? []));
            }
        }
        else {
            onChange(event.currentTarget.files?.[0] ?? null);
        }
    };
    const reset = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };
    assignRef(resetRef, reset);
    const mergedRefs = useMergeRefs(ref, inputRef);
    return (<>
        {children({ onClick, ...others })}

        <input ref={mergedRefs} accept={accept} capture={capture} form={form} multiple={multiple} 
    // Use onClick event to clear value of target input, each time user clicks on field.
    // This ensures that the onChange event will be triggered for the same file as well.
    name={name} style={{ display: 'none' }} type="file" onChange={handleChange} onClick={(event) => (event.target.value = '')} {...inputProps}/>
      </>);
});
FileButton.displayName = 'FileButton';
