import { useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { MotionBox } from './MotionBox';
export const ActiveIndicator = () => (<Box 
// Top required to align it with CircleIndicators
backgroundColor="interaction.support.selected" borderRadius="full" height="0.5rem" position="absolute" top="0.125rem" width="1.5rem"/>);
export const CircleIndicator = ({ onClick, isActiveIndicator, ...props }) => {
    return (<Box _focus={isActiveIndicator
            ? undefined
            : {
                backgroundColor: 'interaction.support.unselected',
                boxShadow: `0 0 0 1px var(--chakra-colors-secondary-400)`,
            }} _hover={{ backgroundColor: 'interaction.support.unselected' }} as="button" backgroundClip="content-box" backgroundColor="interaction.support.unselected" borderRadius="full" height="0.75rem" marginRight={isActiveIndicator ? '1.25rem' : '0.25rem'} padding="0.125rem" width="0.75rem" onClick={onClick} {...props}/>);
};
export const ProgressIndicator = ({ numIndicators, currActiveIdx, onClick, }) => {
    const indicators = useMemo(() => Array(numIndicators).fill(1), [numIndicators]);
    const animationProps = useMemo(() => {
        return { x: `${currActiveIdx + 0.125}rem` };
    }, [currActiveIdx]);
    return (<Box alignSelf="center" display="inline-flex">
      {indicators.map((_, idx) => (<CircleIndicator key={idx} aria-label={`Page ${idx + 1} of ${numIndicators}`} isActiveIndicator={idx === currActiveIdx} onClick={() => onClick(idx)}/>))}

      <MotionBox 
    // Absolute positioning is required for the active progress indicator to slide over inactive ones
    animate={animationProps} pos="absolute" transition={{ stiffness: '100' }}>
        <ActiveIndicator />
      </MotionBox>
    </Box>);
};
