import { z } from 'zod'

import {
  requiredNonEmptyString,
  sgMobileNumberSchema,
} from '@activesg/common/utilities'

export const updateMobileSchema = z.object({
  mobile: sgMobileNumberSchema,
})

const otpSchema = z.object({
  token: z
    .string()
    .trim()
    .min(1, 'OTP is required.')
    .length(6, `Enter a 6-digit code`),
})

export const verifyMobileOtpSchema = updateMobileSchema.merge(otpSchema)

export const updateEmailSchema = z.object({
  email: requiredNonEmptyString.email(),
})

export const verifyEmailOtpSchema = updateEmailSchema.merge(otpSchema)
