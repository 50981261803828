import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { anatomy } from '@chakra-ui/theme-tools';
const parts = anatomy('footer').parts('container', 'section', 'brandContainer', 'link');
// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);
const variantFull = definePartsStyle({
    container: {
        px: 0,
    },
    brandContainer: {
        paddingBottom: { base: '1.5rem', lg: 0 },
    },
});
const variants = {
    full: variantFull,
};
export const Footer = defineMultiStyleConfig({
    variants,
    defaultProps: {
        variant: 'full',
    },
});
