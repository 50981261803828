import {
  chakra,
  FormControl,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Stack,
} from '@chakra-ui/react'
import {
  Button,
  FormErrorMessage,
  FormLabel,
  Input,
  useIsMobile,
} from '@opengovsg/design-system-react'
import { Controller, useFormState } from 'react-hook-form'

import { PinInput } from '@activesg/ui/components'

import { useVerifyMobile } from '../../VerifyMobileProvider'

export const VerifyOtpContent = (): JSX.Element | null => {
  const isMobile = useIsMobile()

  const {
    vfnStepData,
    handleVerifyOtp,
    isLoading,
    otpFormProps,
    handleResendOtp,
    isSendOtpLoading,
    isVerifyOtpLoading,
    resendTimer,
  } = useVerifyMobile()

  const { control } = otpFormProps

  const { isSubmitting, errors } = useFormState({
    control,
  })

  if (!vfnStepData) {
    return null
  }

  return (
    <>
      <ModalHeader pr="5rem">Verify {vfnStepData.mobile}</ModalHeader>
      <chakra.form
        noValidate
        display="flex"
        flex={1}
        flexDirection="column"
        onSubmit={handleVerifyOtp}
      >
        <ModalBody flex={1} whiteSpace="pre-wrap">
          <FormControl
            isRequired
            id="token"
            isInvalid={!!errors.token}
            isReadOnly={isSubmitting}
          >
            <FormLabel>
              Enter and submit the 6-digit code sent to your mobile below:
            </FormLabel>
            <Controller
              control={control}
              name="token"
              render={({ field }) => (
                <PinInput
                  shouldAutoFocus
                  autoComplete="one-time-code"
                  inputType="number"
                  {...field}
                  numInputs={6}
                  renderInput={(props) => (
                    <Input
                      {...props}
                      height={{ base: '2.5rem', md: '3rem' }}
                      px={0}
                      textAlign="center"
                      width={{ base: '2.5rem', md: '3rem' }}
                    />
                  )}
                />
              )}
            />
            <FormErrorMessage>{errors.token?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Stack
            direction={{ base: 'column', md: 'row-reverse' }}
            spacing="0.75rem"
            w="100%"
          >
            <Button
              isFullWidth={isMobile}
              isLoading={isVerifyOtpLoading}
              type="submit"
            >
              Submit
            </Button>
            <Button
              isDisabled={isLoading || resendTimer !== 0}
              isFullWidth={isMobile}
              isLoading={isSendOtpLoading}
              minW="11rem"
              variant="outline"
              onClick={handleResendOtp}
            >
              Resend code{resendTimer > 0 ? ` in ${resendTimer}` : ''}
            </Button>
          </Stack>
        </ModalFooter>
      </chakra.form>
    </>
  )
}
