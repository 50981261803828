import { defineStyle } from '@chakra-ui/react';
import { darken } from '@chakra-ui/theme-tools';
const baseStyle = defineStyle(() => {
    return {
        borderRadius: 'full',
        textDecorationLine: 'none',
        _hover: {
            textDecorationLine: 'none',
        },
    };
});
const variantSolid = defineStyle(({ colorScheme: c, theme }) => {
    if (c !== 'secondary')
        return {};
    return {
        bg: 'brand.primary.500',
        borderRadius: 'full',
        borderColor: 'brand.primary.500',
        px: '15px',
        _active: {
            bg: darken('brand.primary.500', 20)(theme),
            borderColor: darken('brand.primary.500', 20)(theme),
        },
        _hover: {
            bg: darken('brand.primary.500', 10)(theme),
            borderColor: darken('brand.primary.500', 10)(theme),
            _disabled: {
                bg: 'interaction.support.disabled',
                borderColor: 'interaction.support.disabled',
            },
        },
    };
});
export const Button = {
    baseStyle,
    variants: {
        solid: variantSolid,
    },
};
