import { useEffect, } from 'react';
import { useRouter } from 'next/router';
import { FullscreenSpinner } from './FullscreenSpinner';
/**
 * This component is for use whenever you wish to redirect to another page on a condition without flicker.
 * The flicker can potentially last longer on low network speeds as redirecting to any page with a slug for the first time may take a few seconds.
 */
export const ConditionalRedirectWrapper = ({ shouldRedirect, redirectTo, redirectMethod = 'replace', redirectingFallback = <FullscreenSpinner />, children, }) => {
    const router = useRouter();
    useEffect(() => {
        if (shouldRedirect) {
            switch (redirectMethod) {
                case 'push':
                    void router.push(redirectTo);
                    break;
                case 'replace':
                    void router.replace(redirectTo);
                    break;
                default:
                    redirectMethod;
                    throw new Error('Invalid redirect method');
            }
        }
    }, [redirectMethod, redirectTo, router, shouldRedirect]);
    // This shows when we are in the midst of redirecting to a new page
    // needed since router pushes are async, there might be flicker of old page
    if (shouldRedirect) {
        return redirectingFallback;
    }
    return children;
};
