import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import { theme as ogpDsTheme } from '@opengovsg/design-system-react';
import { merge } from 'lodash-es';
import { env } from '@activesg/env';
import { components } from './components';
import { breakpoints } from './foundations/breakpoints';
import { colors } from './foundations/colors';
import { extendedColours } from './foundations/extendedColors';
import { shadows } from './foundations/shadows';
import { layerStyles } from './layerStyles';
import { textStyles } from './textStyles';
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const commonComponents = {
    ...ogpDsTheme.components,
    ...components,
};
function withColorPalatte() {
    function replaceColors(colors, replace) {
        for (const key in colors) {
            if (!colors[key]) {
                continue;
            }
            if (typeof colors[key] === 'object') {
                replaceColors(colors[key], replace);
            }
            else {
                const replacement = replace.get(colors[key]);
                if (replacement) {
                    colors[key] = replacement;
                }
            }
        }
        return colors;
    }
    const STAGING_OVERRIDES = new Map([
        ['#FEF7F7', '#F5FBFC'],
        ['#FBE9E9', '#DDF2F3'],
        ['#EFADAC', '#79CCD1'],
        ['#EA8181', '#30B0B8'],
        ['#E35258', '#1B92A3'],
        ['#CB0935', '#157383'],
        ['#AC0B2F', '#115C6B'],
        ['#880D27', '#0F505E'],
        ['#750F20', '#0C4552'],
        ['#580D0F', '#093440'],
        ['#E5481C', '#30B0B8'],
        ['rgba(203, 9, 53, 0.04)', 'rgba(21, 115, 131, 0.04)'],
        ['rgba(203, 9, 53, 0.12)', 'rgba(21, 115, 131, 0.12)'],
    ]);
    const UAT_OVERRIDES = new Map([
        ['#FEF7F7', '#F8F9FA'],
        ['#FBE9E9', '#E9EAEE'],
        ['#EFADAC', '#BABECB'],
        ['#EA8181', '#9AA0B3'],
        ['#E35258', '#7B849C'],
        ['#CB0935', '#5D6785'],
        ['#AC0B2F', '#465173'],
        ['#880D27', '#3C4764'],
        ['#750F20', '#333C56'],
        ['#580D0F', '#272D41'],
        ['#E5481C', '#9AA0B3'],
        ['rgba(203, 9, 53, 0.04)', 'rgba(93, 103, 133, 0.04)'],
        ['rgba(203, 9, 53, 0.12)', 'rgba(93, 103, 133, 0.12)'],
    ]);
    const SAVED_OVERRIDES = {
        red: {
            '50': '#FEF7F7',
            '100': '#FBE9E9',
            '200': '#EFADAC',
            '300': '#EA8181',
            '400': '#E35258',
            '500': '#CB0935',
            '600': '#AC0B2F',
            '700': '#880D27',
            '800': '#750F20',
            '900': '#580D0F',
        },
        interaction: {
            sub: {
                default: '#171717',
                hover: '#0A0A0A',
                active: '#0A0A0A',
            },
            'sub-subtle': {
                default: '#E5E5E5',
                hover: '#D4D4D4',
                active: '#B0B0B0',
            },
            critical: {
                default: '#CB0935',
                hover: '#AC0B2F',
                active: '#880D27',
            },
            'critical-subtle': {
                default: '#FBE9E9',
                hover: '#EFADAC',
                active: '#EA8181',
            },
            warning: {
                default: '#FFDA68',
                hover: '#E2B73E',
                active: '#C4992A',
            },
            'warning-subtle': {
                default: '#fffae1',
                hover: '#FFDA68',
                active: '#E2B73E',
            },
            success: {
                default: '#0F796F',
                hover: '#0C5F57',
                active: '#0A524B',
            },
            'success-subtle': {
                default: '#E2EEED',
                hover: '#98CCC7',
                active: '#63B1AA',
            },
            neutral: {
                default: '#525252',
                hover: '#404040',
                active: '#262626',
            },
            'neutral-subtle': {
                default: '#E5E5E5',
                hover: '#D4D4D4',
                active: '#B0B0B0',
            },
            muted: {
                sub: {
                    hover: '#FAFAFA',
                    active: '#E5E5E5',
                },
                critical: {
                    hover: '#FEF7F7',
                    active: '#FBE9E9',
                },
                neutral: {
                    hover: '#FAFAFA',
                    active: '#E5E5E5',
                },
            },
            tinted: {
                sub: {
                    hover: 'rgba(82, 82, 82, 0.04)',
                    active: 'rgba(82, 82, 82, 0.12)',
                },
                critical: {
                    hover: 'rgba(203, 9, 53, 0.04)',
                    active: 'rgba(203, 9, 53, 0.12)',
                },
                neutral: {
                    hover: 'rgba(0, 0, 0, 0.1)',
                    active: 'rgba(0, 0, 0, 0.2)',
                },
                inverse: {
                    hover: 'rgba(255, 255, 255, 0.1)',
                    active: 'rgba(255, 255, 255, 0.2)',
                },
            },
            support: {
                unselected: '#B0B0B0',
                selected: '#525252',
                'unselected-strong': '#525252',
                disabled: '#E5E5E5',
                'disabled-content': '#B0B0B0',
                placeholder: '#737373',
            },
            links: {
                'neutral-default': '#262626',
                'neutral-hover': '#0A0A0A',
                'inverse-default': '#FFFFFF',
                'inverse-hover': '#E5E5E5',
            },
        },
        utility: {
            feedback: {
                info: '#171717',
                'info-subtle': '#FAFAFA',
                warning: '#FFDA68',
                'warning-subtle': '#FFFAE1',
                success: '#0F796F',
                'success-subtle': '#F5FAFA',
                critical: '#CB0935',
                'critical-subtle': '#FEF7F7',
            },
            'focus-default': '#171717',
            'focus-inverse': '#FFFFFF',
            'input-prefilled': '#FFFAE1',
            ui: '#FFFFFF',
            'ui-clear': 'rgba(255, 255, 255, 0)',
        },
    };
    switch (env.NEXT_PUBLIC_ENVIRONMENT) {
        case 'staging':
            return merge(replaceColors(merge(colors, extendedColours), STAGING_OVERRIDES), SAVED_OVERRIDES);
        case 'uat':
            return merge(replaceColors(merge(colors, extendedColours), UAT_OVERRIDES), SAVED_OVERRIDES);
        default:
            return merge(colors, extendedColours);
    }
}
export const theme = extendTheme(ogpDsTheme, {
    styles: {
        global: {
            html: {
                background: 'base.canvas.default',
            },
        },
    },
    breakpoints,
    shadows,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    components: commonComponents,
    colors: withColorPalatte(),
    sizes: {
        appNavbar: '3.5rem',
    },
    layerStyles,
    textStyles,
    fonts: {
        stamp: 'Dosis, monospace',
    },
}, withDefaultColorScheme({
    colorScheme: 'brand.primary',
    components: ['Link'],
}));
