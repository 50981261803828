export const AUTO_COMPLETE = 'N';
export const SUBMIT = 'Y';
export const LEDGER_TYPE = 'Actuals';
export const ACCOUNT_SET = 'Standard';
export const INTERFACE_TYPE_NCJ = 'NCJ';
// should we lower this to have margin of safety?
export const ROW_LIMIT_PER_WORKBOOK = 10000;
export const ACCOUNTING_JOURNAL_SHEET_1 = 'Import Accounting Journal';
export const ACCOUNTING_JOURNAL_SHEET_2 = 'Journal Entry Line Replacement';
// TODO: recreate first four rows of the IAJ
export const IAJ_CELL_A1 = 'Import Accounting Journal';
export const IAJ_HEADERS = {
    headerKey: { title: 'Header Key*', mandatory: true },
    allAutoComplete: { title: 'Auto Complete', mandatory: true },
    comment: { title: 'Comment', mandatory: false },
    worker: { title: 'Worker', mandatory: false },
    accountingJournalId: { title: 'Accounting Journal ID', mandatory: false },
    submit: { title: 'Submit', mandatory: true },
    disableOptionalWorktagBalancing: {
        title: 'Disable Optional Worktag Balancing',
        mandatory: false,
    },
    lockedInWorkday: { title: 'Locked In Workday', mandatory: false },
    roundLedgerAmounts: { title: 'Round Ledger Amounts', mandatory: false },
    journalNumber: { title: 'Journal Number', mandatory: false },
    company: { title: 'Company*', mandatory: true },
    currency: { title: 'Currency*', mandatory: true },
    ledgerType: { title: 'Ledger Type*', mandatory: true },
    bookCode: { title: 'Book Code', mandatory: false },
    accountingDate: { title: 'Accounting Date*', mandatory: true },
    journalSource: { title: 'Journal Source*', mandatory: true },
    balancingWorktag: { title: 'Balancing Worktag', mandatory: false },
    optionalBalancingWorktagsPlus: {
        title: 'Optional Balancing Worktags+',
        mandatory: false,
    },
    recordQuantity: { title: 'Record Quantity', mandatory: false },
    journalEntryMemo: { title: 'Journal Entry Memo', mandatory: false },
    externalReferenceId: { title: 'External Reference ID', mandatory: true },
    documentLink: { title: 'Document Link', mandatory: false },
    adjustmentJournal: { title: 'Adjustment Journal', mandatory: false },
    includeTaxLines: { title: 'Include Tax Lines', mandatory: true },
    createReversal: { title: 'Create Reversal', mandatory: false },
    reversalDate: { title: 'Reversal Date', mandatory: false },
    controlTotalAmount: { title: 'Control Total Amount', mandatory: false },
    currencyRateType: { title: 'Currency Rate Type', mandatory: false },
    displayAccountSet: { title: 'Display Account Set', mandatory: false },
    // attachment data+; all blank
    rowId: { title: 'Row ID*', mandatory: false },
    contentType: { title: 'Content Type', mandatory: false },
    filename: { title: 'Filename', mandatory: false },
    encoding: { title: 'Encoding', mandatory: false },
    compressed: { title: 'Compressed', mandatory: false },
    fileContent: { title: 'File Content', mandatory: false },
    companiesWithoutAccessPlus: {
        title: 'Companies Without Access+',
        mandatory: false,
    },
    attachmentDataComment: { title: 'Comment', mandatory: false },
    accountingJournalDataAutoComplete: {
        title: 'Auto Complete',
        mandatory: false,
    },
    skip: { title: 'Skip', mandatory: false },
    businessProcessCommentDataComment: { title: 'Comment', mandatory: false },
    businessProcessCommentDataWorker: { title: 'Worker', mandatory: false },
    businessProcessCommentDataRowId: { title: 'Row ID*', mandatory: false },
    fileName: { title: 'File Name*', mandatory: false },
    eventAttachmentDescription: {
        title: 'Event Attachment Description',
        mandatory: false,
    },
    eventAttachmentCategory: {
        title: 'Event Attachment Category',
        mandatory: false,
    },
    file: { title: 'File', mandatory: false },
    businessProcessCommentDataContentType: {
        title: 'Content Type',
        mandatory: false,
    },
};
// TODO: recreate first five rows of JELR
export const JELR_CELL_A1 = 'Journal Entry Line Replacement Data';
export const JELR_HEADERS = {
    headerKey: { title: 'Header Key', mandatory: true },
    lineKey: { title: 'Line Key', mandatory: true },
    lineOrder: { title: 'Line Order', mandatory: false },
    lineCompany: { title: 'Line Company', mandatory: false },
    ledgerAccount: { title: 'Ledger Account', mandatory: true },
    accountingSet: { title: 'Accounting Set', mandatory: true },
    debitAmount: { title: 'Debit Amount', mandatory: false },
    creditAmount: { title: 'Credit Amount', mandatory: false },
    currency: { title: 'Currency', mandatory: true },
    currencyRate: { title: 'Currency Rate', mandatory: false },
    memo: { title: 'Memo', mandatory: true },
    externalReferenceId: { title: 'External Reference ID', mandatory: true },
    budgetDate: { title: 'Budget Date', mandatory: false },
    intercompanyAffiliate: { title: 'Intercompany Affiliate', mandatory: false },
    project: { title: 'Project', mandatory: false },
    costCenter: { title: 'Cost Center', mandatory: true },
    // not sure, could be mandatory?
    program: { title: 'Program', mandatory: false },
    fund: { title: 'Fund', mandatory: true },
    spendCategory: { title: 'Spend Category', mandatory: false },
    revenueCategory: { title: 'Revenue Category', mandatory: false },
    grant: { title: 'Grant', mandatory: false },
    relatedParties: { title: 'Related Parties', mandatory: false },
    financialInstrument: { title: 'Financial Instrument', mandatory: false },
    location: { title: 'Location', mandatory: true },
    supplier: { title: 'Supplier', mandatory: false },
    customer: { title: 'Customer', mandatory: false },
    salesItem: { title: 'Sales Item', mandatory: false },
    lifeCycleCost: { title: 'Life Cycle Cost', mandatory: false },
    jobClassificationGroup: {
        title: 'Job Classification Group',
        mandatory: false,
    },
    bankAccount: { title: 'Bank Account', mandatory: false },
    balancingWorktagAffiliate: {
        title: 'Balancing Worktag Affiliate',
        mandatory: false,
    },
    excludeFromSpendReports: {
        title: 'Exclude from Spend Reports',
        mandatory: false,
    },
    // tax detail data, only fill up if tax is applicable
    transactionDate: { title: 'Transaction Date*', mandatory: false },
    taxType: { title: 'Tax Type*', mandatory: false },
    taxableAmount: { title: 'Taxable Amount', mandatory: false },
    taxCode: { title: 'Tax Code*', mandatory: false },
    taxRate: { title: 'Tax Rate*', mandatory: false },
    taxApplicability: { title: 'Tax Applicability', mandatory: false },
    // all blank from here
    taxRecoverability: { title: 'Tax Recoverability', mandatory: false },
    taxPointDateType: { title: 'Tax Point Date Type', mandatory: false },
    taxSupplier: { title: 'Supplier', mandatory: false },
    taxCustomer: { title: 'Customer', mandatory: false },
    externalBusinessEntityName: {
        title: 'External Business Entity Name',
        mandatory: false,
    },
    externalBusinessEntityAddress: {
        title: 'External Business Entity Address',
        mandatory: false,
    },
    externalBusinessEntityTaxID: {
        title: 'External Business Entity Tax ID',
        mandatory: false,
    },
    taxableEventLineReferenceRowId: {
        title: 'Row ID*',
        mandatory: false,
    },
    taxableEventLineReferenceLineOrder: {
        title: 'Line Order',
        mandatory: false,
    },
};
