import { createContext, useCallback, useEffect, } from 'react';
import { useRouter } from 'next/router';
import { GrowthBookProvider as _GrowthBookProvider, } from '@growthbook/growthbook-react';
export const GrowthbookContext = createContext(undefined);
export const GrowthbookProvider = ({ gb, profile, children, }) => {
    const router = useRouter();
    const updateGrowthBookURL = useCallback(() => {
        if (typeof window === 'undefined') {
            return null;
        }
        void gb.setURL(window.location.href);
    }, [gb]);
    useEffect(() => {
        // Load features from the GrowthBook API and keep them up-to-date
        void gb.setAttributes({
            ...gb.getAttributes(),
            profile,
        });
        // Subscribe to route change events and update GrowthBook
        router.events.on('routeChangeComplete', updateGrowthBookURL);
        return () => router.events.off('routeChangeComplete', updateGrowthBookURL);
    }, [gb, profile, router.events, updateGrowthBookURL]);
    return <_GrowthBookProvider growthbook={gb}>{children}</_GrowthBookProvider>;
};
