import React from 'react';
import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import bigInt from 'big-integer';
import { BiCreditCard } from 'react-icons/bi';
import { displayCurrency } from '@activesg/common/utilities';
import { BUSINESS_NAME, GST_REGISTRATION_NUMBER } from '@activesg/constants';
import { InfoSection } from '../InfoSection';
export const PaymentLinkReceiptDetails = ({ itemsMetadata, displayItem, receiptId, }) => {
    // Should never happen
    if (itemsMetadata === null)
        throw new Error('No payment items');
    const totalAmountToPay = itemsMetadata.items.reduce((prev, accum) => prev.add(bigInt(accum.pricePerItemInCents).multiply(accum.quantity)), bigInt(0));
    return (<InfoSection label="Payment details" labelIcon={BiCreditCard}>
      <Stack spacing="1.5rem">
        {displayItem}

        <Box bg="base.canvas.alt" borderColor="base.divider.strong" borderRadius="0.25rem" borderStyle="solid" borderWidth="1px" p="1rem">
          <Stack spacing="1rem">
            {itemsMetadata.items.map((item) => {
            return (<HStack key={item.description} justifyContent="space-between" textStyle="body-2">
                  <Text as="span">
                    {`${item.quantity} \u00D7 `}
                    {`${item.activityVariant}, ${item.description} (${item.timeRestriction})`}
                  </Text>
                  <Text alignSelf="start">{`S$${displayCurrency(bigInt(item.pricePerItemInCents).multiply(item.quantity))}`}</Text>
                </HStack>);
        })}

            <HStack alignItems="baseline" justifyContent="end" spacing="0.25rem">
              <Text color="grey.900">Total: </Text>
              <Text color="grey.900" textStyle="h4">{`S$${displayCurrency(totalAmountToPay)}`}</Text>
            </HStack>

            {receiptId && (<Stack alignItems="flex-end" spacing="0.25rem" textStyle="caption-2" w="full">
                <Text>{receiptId}</Text>
                <HStack justifyContent="space-between" spacing="0.25rem" w="full">
                  <Text>{BUSINESS_NAME}</Text>
                  <Text>GST Reg No: {GST_REGISTRATION_NUMBER}</Text>
                </HStack>
              </Stack>)}
          </Stack>
        </Box>
      </Stack>
    </InfoSection>);
};
