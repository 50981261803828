import { ButtonGroup, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, } from '@chakra-ui/react';
import { Button, ModalCloseButton, useIsMobile, } from '@opengovsg/design-system-react';
import { ResponsiveModal } from '@activesg/ui/components';
export const ConfirmationModal = ({ header, body, button, cancelButton, isOpen, onClose, onClick, isLoading, }) => {
    const isMobile = useIsMobile();
    return (<ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {/* Hack to make ModalHeader not overlap with ModalCloseButton. This is needed as ModalCloseButton is absolutely positioned */}
        <ModalHeader mr="44px">{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <ButtonGroup flexDir={{ base: 'column-reverse', md: 'row' }} gap={{ base: '1rem', md: '0.5rem' }} justifyContent={{ base: 'stretch', md: 'flex-end' }} spacing="0" w="full">
            <Button colorScheme={cancelButton?.colorScheme ?? 'sub'} isFullWidth={isMobile} variant={cancelButton?.variant ?? 'clear'} onClick={onClose}>
              {cancelButton?.label ?? 'Cancel'}
            </Button>
            <Button colorScheme={button.colorScheme} isFullWidth={isMobile} isLoading={isLoading} variant={button.variant ?? 'solid'} onClick={onClick}>
              {button.label}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </ResponsiveModal>);
};
