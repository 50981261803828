import { Icon, Stack, Text } from '@chakra-ui/react';
export const SectionHeader = ({ icon, children, description, ...props }) => (<Stack spacing={0}>
    <Stack align="center" color="base.content.default" direction="row" {...props}>
      {icon && <Icon aria-hidden as={icon} fontSize="1rem"/>}
      <Text as="span" textStyle="subhead-3">
        {children}
      </Text>
    </Stack>
    {description}
  </Stack>);
