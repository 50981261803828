export var Day;
(function (Day) {
    Day["MON"] = "MON";
    Day["TUE"] = "TUE";
    Day["WED"] = "WED";
    Day["THU"] = "THU";
    Day["FRI"] = "FRI";
    Day["SAT"] = "SAT";
    Day["SUN"] = "SUN";
})(Day || (Day = {}));
export var Period;
(function (Period) {
    Period["DAY"] = "DAY";
    Period["WEEK"] = "WEEK";
    Period["MONTH"] = "MONTH";
})(Period || (Period = {}));
export const PAYMENT_ITEM_ENTITIES = [
    'Booking',
    'Pass',
    'ProgrammeSessionBooking',
];
