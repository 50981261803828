import { BANK, BANK_CHARGES, BOOKING_DEFERRED_REVENUE_TAGS, BOOKINGS_REVENUE_TAGS, DEFERRED_REVENUE, INTERFACE_TYPE_AHBT, INTERFACE_TYPE_NCJ, LTP_PROGRAMME_DEFERRED_REVENUE, LTP_PROGRAMME_REVENUE, MISC_REVENUE, NON_LTP_PROGRAMME_DEFERRED_REVENUE, NULL_REVENUE_TAGS, NULL_SPEND_TAGS, OUTPUT_GST, OUTPUT_GST_REVENUE_TAGS, RENTAL_INCOME, SERVICES_RENDERED_REVENUE_CCY, STRIPE_CHARGES_SPEND_TAGS, TAX_APPLICABILITY, TAX_CODE, TAX_RATE, TAX_TYPE, } from '@activesg/constants';
import { dayjs } from '../../utilities/index.js';
export function generateTransactionMemoActiveSGCredits({ ledgerAccountName = 'ActiveSGCredits', reason, }) {
    if (!reason) {
        return `activesg-${ledgerAccountName}-${reason}`;
    }
    return `activesg-${ledgerAccountName}`;
}
export function generateTransactionMemo({ ledgerName, entity, entityId, workdaySalesItemDescription, }) {
    if (!workdaySalesItemDescription) {
        return `activesg-${ledgerName}-${entity}-${entityId}`;
    }
    return `activesg-${ledgerName}-${workdaySalesItemDescription}-${entity}-${entityId}`;
}
export function generateWorkdayExternalReferenceId({ entity, entityId, date, headerKey, }) {
    const formattedDate = dayjs(date).tz().format('YYYYMMDD');
    return `activesg-GL-${formattedDate}-${headerKey.toString()}-${entity}-${entityId}`;
}
export function getWorkdaySpendCategoryTags({ creditLedgerId, debitLedgerId, }) {
    if (creditLedgerId === BANK_CHARGES || debitLedgerId === BANK_CHARGES) {
        return STRIPE_CHARGES_SPEND_TAGS;
    }
    return NULL_SPEND_TAGS;
}
export function getWorkdayRevenueCategoryTags(args) {
    // if either credit ledger or debit ledger touches any revenue/deferred revenue ledger account
    // will return corresponding object
    switch (args.entity) {
        case 'Booking': {
            const { entity, creditLedgerId, debitLedgerId, transactionType, debitOrCredit, } = args;
            switch (transactionType) {
                case 'COLLECTION':
                case 'EXCESS_COLLECTION': {
                    if (
                    // practically debitLedgerId will always be receivables clearing account
                    // but leaving it out since not strictly necessary
                    creditLedgerId === DEFERRED_REVENUE) {
                        return BOOKING_DEFERRED_REVENUE_TAGS;
                    }
                    // practically speaking, at collection, we almost never hit RENTAL_INCOME directly
                    // but just in case
                    if (creditLedgerId === RENTAL_INCOME) {
                        return BOOKINGS_REVENUE_TAGS;
                    }
                    if (creditLedgerId === OUTPUT_GST) {
                        return OUTPUT_GST_REVENUE_TAGS;
                    }
                    return NULL_REVENUE_TAGS;
                }
                case 'REVENUE_RECOGNITION': {
                    // FIXME: Use guard clause instead.
                    if (debitLedgerId === DEFERRED_REVENUE) {
                        switch (debitOrCredit) {
                            case 'credit':
                                return BOOKINGS_REVENUE_TAGS;
                            case 'debit':
                                return BOOKING_DEFERRED_REVENUE_TAGS;
                            default:
                                debitOrCredit;
                                throw new Error(`Unknown debitOrCredit: ${debitOrCredit}`);
                        }
                    }
                    // no other code path should reach here
                    throw new Error(`Should never reach here: ${entity}, ${creditLedgerId}, ${debitLedgerId}, ${transactionType}`);
                }
                case 'EXCESS_REFUND':
                case 'REFUND':
                case 'COLLECTION_ON_BEHALF':
                case 'PAYOUT': {
                    // only check debit ledger because if it's credit ledger, it would be collections/revenue recognition
                    // practically speaking, will only be true for refunds
                    if (debitLedgerId === DEFERRED_REVENUE) {
                        return BOOKING_DEFERRED_REVENUE_TAGS;
                    }
                    if (debitLedgerId === RENTAL_INCOME) {
                        return BOOKINGS_REVENUE_TAGS;
                    }
                    return NULL_REVENUE_TAGS;
                }
                default:
                    transactionType;
                    throw new Error(`Unknown transaction type: ${transactionType}`);
            }
        }
        case 'Pass': {
            // no single "usage date" for passes (unless it's a single pass)
            // so for simplicity, just return null
            throw new Error("Not implemented yet for 'Pass'");
        }
        case 'ProgrammeSessionBooking': {
            const { entity, creditLedgerId, debitLedgerId, transactionType, debitOrCredit, isLtp, } = args;
            switch (transactionType) {
                case 'COLLECTION':
                case 'EXCESS_COLLECTION': {
                    if (
                    // practically debitLedgerId will always be receivables clearing account
                    // but leaving it out since not strictly necessary
                    creditLedgerId === DEFERRED_REVENUE) {
                        return isLtp
                            ? LTP_PROGRAMME_DEFERRED_REVENUE
                            : NON_LTP_PROGRAMME_DEFERRED_REVENUE;
                    }
                    if (creditLedgerId === SERVICES_RENDERED_REVENUE_CCY) {
                        // this is true regardless of isLtp
                        return LTP_PROGRAMME_REVENUE;
                    }
                    if (creditLedgerId === OUTPUT_GST) {
                        return OUTPUT_GST_REVENUE_TAGS;
                    }
                    return NULL_REVENUE_TAGS;
                }
                case 'REVENUE_RECOGNITION': {
                    // FIXME: Use guard clause instead.
                    if (debitLedgerId === DEFERRED_REVENUE) {
                        switch (debitOrCredit) {
                            case 'credit':
                                // this is true regardless of isLtp
                                return LTP_PROGRAMME_REVENUE;
                            case 'debit':
                                return isLtp
                                    ? LTP_PROGRAMME_DEFERRED_REVENUE
                                    : NON_LTP_PROGRAMME_DEFERRED_REVENUE;
                            default:
                                debitOrCredit;
                                throw new Error(`Unknown debitOrCredit: ${debitOrCredit}`);
                        }
                    }
                    // no other code path should reach here
                    throw new Error(`Should never reach here: ${entity}, ${creditLedgerId}, ${debitLedgerId}, ${transactionType}`);
                }
                case 'EXCESS_REFUND':
                case 'REFUND':
                case 'COLLECTION_ON_BEHALF':
                case 'PAYOUT': {
                    // only check debit ledger because if it's credit ledger, it would be collections/revenue recognition
                    // practically speaking, will only be true for refunds
                    if (debitLedgerId === DEFERRED_REVENUE) {
                        return isLtp
                            ? LTP_PROGRAMME_DEFERRED_REVENUE
                            : NON_LTP_PROGRAMME_DEFERRED_REVENUE;
                    }
                    if (debitLedgerId === SERVICES_RENDERED_REVENUE_CCY) {
                        // this is true regardless of isLtp
                        return LTP_PROGRAMME_REVENUE;
                    }
                    return NULL_REVENUE_TAGS;
                }
                default:
                    transactionType;
                    throw new Error(`Unknown transaction type: ${transactionType}`);
            }
        }
        default: {
            args;
            throw new Error(`Unknown entity: ${args}`);
        }
    }
}
export function getInterfaceType(creditLedgerId, debitLedgerId) {
    if (creditLedgerId === BANK || debitLedgerId === BANK) {
        return INTERFACE_TYPE_AHBT;
    }
    return INTERFACE_TYPE_NCJ;
}
export function getWorkdayTaxTags(creditLedgerId, debitLedgerId) {
    if (creditLedgerId === OUTPUT_GST || debitLedgerId === OUTPUT_GST) {
        return {
            taxType: TAX_TYPE,
            taxCode: TAX_CODE,
            taxRate: TAX_RATE,
            taxApplicability: TAX_APPLICABILITY,
        };
    }
    return {
        taxType: null,
        taxCode: null,
        taxRate: null,
        taxApplicability: null,
    };
}
export function includeWorkdayRevenueTags(ledgerId) {
    return (ledgerId === DEFERRED_REVENUE ||
        ledgerId === RENTAL_INCOME ||
        ledgerId === SERVICES_RENDERED_REVENUE_CCY ||
        ledgerId === MISC_REVENUE ||
        ledgerId === OUTPUT_GST);
}
export function includeWorkdaySpendTags(ledgerId) {
    return ledgerId === BANK_CHARGES;
}
export function includeWorkdayTaxTags(ledgerId) {
    return ledgerId === OUTPUT_GST;
}
