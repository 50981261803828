import bigInt from 'big-integer';
/**
 * ! This function assumes the `amount` param is configured with 2 decimal places
 */
export const displayCurrency = (amount) => {
    // Remove any decimal points
    let stringified = amount.toString().replace('.', '');
    const isNegative = stringified.startsWith('-');
    if (isNegative) {
        stringified = stringified.slice(1);
    }
    const cents = stringified.slice(-2).padStart(2, '0');
    const dollars = stringified.slice(0, -2);
    if (!dollars.length && cents === '0') {
        return '0';
    }
    return `${isNegative ? '-' : ''}${dollars === '' ? '0' : dollars}.${cents}`;
};
export const displayCurrencyWithPrefix = (amount, prefix = '$') => {
    const amountString = displayCurrency(amount);
    if (amountString.startsWith(`-`)) {
        return `-${prefix}${amountString.slice(1)}`;
    }
    return `${prefix}${amountString}`;
};
export const getDiscountedPrice = ({ priceInCents, discountPct, }) => {
    const parsedDiscountPct = bigInt(discountPct);
    if (parsedDiscountPct.compare(100) === 1) {
        throw new Error('Discount percentage cannot be greater than 100');
    }
    if (parsedDiscountPct.compare(0) === -1) {
        throw new Error('Discount percentage cannot be negative');
    }
    return bigInt(priceInCents).subtract(bigInt(priceInCents).multiply(parsedDiscountPct).divide(100));
};
