import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { memoizedGet as get } from '@chakra-ui/utils';
// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);
const variantMonochromePill = definePartsStyle(({ theme }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const themeTextStyles = get(theme, 'textStyles');
    return {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        tab: {
            _selected: {
                bgColor: 'interaction.sub.default',
                color: 'base.content.inverse',
            },
            bgColor: '#F1F1F1',
            borderRadius: '50px',
            color: 'base.content.strong',
            m: '0rem',
            position: 'relative',
            px: '1rem',
            py: '0.625rem',
            textTransform: 'capitalize',
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            ...themeTextStyles['caption-1'],
        },
        tablist: {
            gap: '0.5rem',
        },
    };
});
export const Tabs = defineMultiStyleConfig({
    variants: {
        'monochrome-pill': variantMonochromePill,
    },
});
