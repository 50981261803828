import { useRouter } from 'next/router';
import { createRedirectUrlSchema } from '@activesg/common/schemas';
import { REDIRECT_URL_KEY } from '@activesg/constants';
import { useLoginState } from '../../../hooks';
import { FullscreenSpinner } from '../../FullscreenSpinner';
/**
 * Page wrapper that renders children only if the login cookie is NOT found.
 * Otherwise, will redirect to the route passed into the `REDIRECT_URL_KEY` URL parameter.
 *
 * @note There is no authentication being performed by this component. This component is merely a wrapper that checks for the presence of the login flag in localStorage.
 */
export const PublicPageWrapper = ({ redirect, children, }) => {
    const router = useRouter();
    const { hasLoginStateFlag } = useLoginState();
    if (hasLoginStateFlag && redirect.strict) {
        const redirectUrl = String(router.query[REDIRECT_URL_KEY] ?? redirect.defaultUrl);
        void router.replace(createRedirectUrlSchema(redirect.defaultUrl).parse(redirectUrl));
        return <FullscreenSpinner />;
    }
    return <>{children}</>;
};
