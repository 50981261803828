import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { anatomy } from '@chakra-ui/theme-tools';
const parts = anatomy('sidebar').parts('item', 'list', 'header', 'label', 'nest', 'section', 'parent');
// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);
const baseStyle = definePartsStyle({
    parent: {
        textAlign: 'start',
    },
});
export const Sidebar = defineMultiStyleConfig({
    baseStyle,
});
