import { useState } from 'react';
export const usePagination = ({ totalCount, ...state }) => {
    const [pagination, setPagination] = useState(state);
    const { pageSize, pageIndex } = pagination;
    const pageCount = Math.ceil(totalCount / pageSize);
    return {
        limit: pageSize,
        pageCount,
        onPaginationChange: setPagination,
        pagination,
        skip: pageSize * pageIndex,
    };
};
