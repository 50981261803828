import { isValidPhoneNumber, parsePhoneNumberFromString, } from 'libphonenumber-js/max';
import { z } from 'zod';
/**
 * Check if a number is a valid Singapore number
 * @param number
 * @returns true if validate, false otherwise
 */
function isSingaporeNumber(number) {
    const parsedNumber = parsePhoneNumberFromString(number, 'SG');
    return !!parsedNumber && parsedNumber.isPossible();
}
/**
 * @param phoneNumber
 * @returns Singapore number in the format 9123 4567
 */
export function formatSgpNumber(phoneNumber) {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'SG');
    if (!isSingaporeNumber(phoneNumber) || !parsedNumber) {
        console.error(`Not a Singapore phone number: ${phoneNumber}`);
        return phoneNumber;
    }
    return parsedNumber.formatNational();
}
/**
 * @param number
 * @returns Singapore number in E.164 format, i.e. +6591234567
 */
export function formatSgpNumberE164(number) {
    const parsedNumber = parsePhoneNumberFromString(number, 'SG');
    if (!parsedNumber?.isPossible()) {
        throw new Error('Not a phone number');
    }
    if (!isValidPhoneNumber(number, 'SG')) {
        // not a Singapore phone number, return as is
        return number;
    }
    return parsedNumber.format('E.164');
}
/**
 * Helper util to do necessary transformation and validations for sgMobileNumberSchema.
 *
 * This is especially useful for optional sgMobileNumbers
 */
export const sgMobileNumberSchemaBuilder = {
    optional() {
        return z
            .string()
            .trim()
            .optional()
            .transform((v) => (v === '' ? undefined : v))
            .refine((v) => {
            if (v === undefined) {
                return true;
            }
            return (isSingaporeNumber(v),
                {
                    message: 'Enter a valid Singapore mobile number',
                });
        })
            .transform((v, ctx) => {
            if (v) {
                try {
                    return formatSgpNumberE164(v);
                }
                catch (error) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: error instanceof Error ? error.message : 'Unknown error',
                        fatal: true,
                    });
                    return z.NEVER;
                }
            }
            return v;
        });
    },
    required() {
        return z
            .string()
            .refine((v) => isSingaporeNumber(v), {
            message: 'Enter a valid Singapore mobile number',
        })
            .transform((v, ctx) => {
            try {
                return formatSgpNumberE164(v);
            }
            catch (error) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: error instanceof Error ? error.message : 'Unknown error',
                    fatal: true,
                });
                return z.NEVER;
            }
        });
    },
    nullish() {
        return z
            .string()
            .trim()
            .nullish()
            .transform((v) => (v === '' ? undefined : v))
            .refine((v) => {
            if (v === undefined || v === null) {
                return true;
            }
            return (isSingaporeNumber(v),
                {
                    message: 'Enter a valid Singapore mobile number',
                });
        })
            .transform((v, ctx) => {
            if (v) {
                try {
                    return formatSgpNumberE164(v);
                }
                catch (error) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: error instanceof Error ? error.message : 'Unknown error',
                        fatal: true,
                    });
                    return z.NEVER;
                }
            }
            return v;
        });
    },
};
/**
 * @deprecated use `sgMobileNumberSchemaBuilder.required()` instead
 */
export const sgMobileNumberSchema = z
    .string()
    .refine((v) => isSingaporeNumber(v), {
    message: 'Enter a valid Singapore mobile number',
})
    .transform((v, ctx) => {
    try {
        return formatSgpNumberE164(v);
    }
    catch (error) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: error instanceof Error ? error.message : 'Unknown error',
            fatal: true,
        });
        return z.NEVER;
    }
});
export const sgMobileNumberSchemaNullable = sgMobileNumberSchema.nullable();
