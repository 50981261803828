export const PASSES_ROUTES = {
    root: () => '/passes',
    activities: () => ({
        root: () => `${PASSES_ROUTES.root()}/activities`,
        options: (id) => `${PASSES_ROUTES.activities().root()}/${id}`,
    }),
    view: (passId) => `${PASSES_ROUTES.root()}/${passId}`,
    selectAssignee: () => ({
        root: () => `${PASSES_ROUTES.root()}/select-assignee`,
        multi: (typeId, age) => `${PASSES_ROUTES.selectAssignee().root()}/multi/${typeId}/${age}`,
        single: (typeId, selected) => {
            const searchParams = new URLSearchParams({
                selection: JSON.stringify(selected),
            });
            return `${PASSES_ROUTES.selectAssignee().root()}/single/${typeId}?${searchParams.toString()}`;
        },
    }),
    review: () => ({
        root: () => `${PASSES_ROUTES.root()}/review`,
        paymentId: (paymentId) => `${PASSES_ROUTES.review().root()}/${paymentId}`,
    }),
    confirmation: () => ({
        root: () => `${PASSES_ROUTES.root()}/confirmation`,
        paymentId: (paymentId) => `${PASSES_ROUTES.confirmation().root()}/${paymentId}`,
    }),
    myPasses: () => ({
        root: () => `${PASSES_ROUTES.root()}/my-passes`,
        paymentId: (paymentId) => `${PASSES_ROUTES.myPasses().root()}/${paymentId}`,
        seniorCitizenPass: () => ({
            root: () => `${PASSES_ROUTES.myPasses().root()}/senior-citizen-pass`,
            gym: () => `${PASSES_ROUTES.myPasses().seniorCitizenPass().root()}/gym`,
            swim: () => `${PASSES_ROUTES.myPasses().seniorCitizenPass().root()}/swim`,
        }),
    }),
    paymentLink: () => ({
        root: () => `${PASSES_ROUTES.root()}/payment-link`,
        paymentLinkId: (paymentLinkId) => `${PASSES_ROUTES.paymentLink().root()}/${paymentLinkId}`,
    }),
};
