import { OtpMachineState } from '~/features/settings/state'

import { useVerifyMobile } from '../../VerifyMobileProvider'
import { EnterMobileContent } from './EnterMobileContent'
import { SuccessContent } from './SuccessContent'
import { VerifyOtpContent } from './VerifyOtpContent'

export const VerifyMobileContent = (): JSX.Element => {
  const { state } = useVerifyMobile()

  switch (state) {
    case OtpMachineState.Idle:
      return <EnterMobileContent />
    case OtpMachineState.Verifying:
      return <VerifyOtpContent />
    case OtpMachineState.Verified:
      return <SuccessContent />
  }
}
