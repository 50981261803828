import { CardBody, CardHeader, HStack, Icon, Text, } from '@chakra-ui/react';
import { InformationalCard } from './InfomationalCard';
export const InfoSection = ({ label, labelIcon, children, }) => {
    return (<InformationalCard>
      {label && (<CardHeader>
          <HStack color="base.content.default" textStyle="subhead-3">
            {labelIcon && <Icon as={labelIcon} fontSize="1rem"/>}
            <Text>{label}</Text>
          </HStack>
        </CardHeader>)}
      <CardBody>{children}</CardBody>
    </InformationalCard>);
};
