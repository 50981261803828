export const PROGRAMMES_ROUTES = {
    root: () => '/programmes',
    list: () => '/programmes',
    activities: () => `${PROGRAMMES_ROUTES.root()}/activities`,
    view: (programmeId) => `${PROGRAMMES_ROUTES.root()}/${programmeId}`,
    selectAttendee: (programmeId) => `${PROGRAMMES_ROUTES.view(programmeId)}/select-attendee`,
    review: () => ({
        root: () => `${PROGRAMMES_ROUTES.root()}/review`,
        paymentId: (paymentId) => `${PROGRAMMES_ROUTES.review().root()}/${paymentId}`,
    }),
    confirmation: () => ({
        root: () => `${PROGRAMMES_ROUTES.root()}/confirmation`,
        paymentId: (paymentId) => `${PROGRAMMES_ROUTES.confirmation().root()}/${paymentId}`,
    }),
    myProgrammes: () => `${PROGRAMMES_ROUTES.root()}/my-programmes`,
};
