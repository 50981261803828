export const FEEDBACK_QUESTIONS = [
    'How was the scanning experience today?',
    'How likely are you to recommend us to a friend or colleague?',
];
// The transaction/interaction for which the feedback was collected.
export const FEEDBACK_EVENTS = [
    'payment', // Collected at payment completed/receipt page.
    'facility-booking-ballot-result-released', // Collected on facility booking ballot result page.
    'pass-scanned', // Collected on pass QR code page.
];
