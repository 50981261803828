import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { anatomy } from '@chakra-ui/theme-tools';
const parts = anatomy('banner').parts('banner');
// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);
const variantInfo = definePartsStyle({
    banner: {
        bg: 'base.canvas.gradient-backdrop',
    },
});
const variants = {
    info: variantInfo,
};
export const Banner = defineMultiStyleConfig({
    variants,
});
