import { Badge } from './Badge';
import { Banner } from './Banner';
import { Breadcrumb } from './Breadcrumb';
import { Button } from './Button';
import { cardTheme } from './Card';
import { Footer } from './Footer';
import { FormLabel } from './FormLabel';
import { Infobox } from './Infobox';
import { Input } from './Input';
import { Menu } from './Menu';
import { Modal } from './Modal';
import { NumberInput } from './NumberInput';
import { Sidebar } from './Sidebar';
import { Stepper } from './Stepper';
import { Table } from './Table';
import { Tabs } from './Tabs';
import { Tag } from './Tag';
import { TimeCalendar } from './TimeCalendar';
export const components = {
    Badge,
    Banner,
    Button,
    Infobox,
    Card: cardTheme,
    Input,
    NumberInput,
    Menu,
    Sidebar,
    Stepper,
    Table,
    TimeCalendar,
    Breadcrumb,
    FormLabel,
    Tag,
    Footer,
    Modal,
    Tabs,
};
