/**
 * Do not edit directly
 * Generated on Mon, 01 Jul 2024 10:13:40 GMT
 */
export const colors = {
    red: {
        '50': '#FEF7F7',
        '100': '#FBE9E9',
        '200': '#EFADAC',
        '300': '#EA8181',
        '400': '#E35258',
        '500': '#CB0935',
        '600': '#AC0B2F',
        '700': '#880D27',
        '800': '#750F20',
        '900': '#580D0F',
    },
    brand: {
        primary: {
            '50': '#FEF7F7',
            '100': '#FBE9E9',
            '200': '#EFADAC',
            '300': '#EA8181',
            '400': '#E35258',
            '500': '#CB0935',
            '600': '#AC0B2F',
            '700': '#880D27',
            '800': '#750F20',
            '900': '#580D0F',
        },
        secondary: {
            '50': '#FAFAFA',
            '100': '#E5E5E5',
            '200': '#D4D4D4',
            '300': '#B0B0B0',
            '400': '#737373',
            '500': '#525252',
            '600': '#404040',
            '700': '#262626',
            '800': '#171717',
            '900': '#0a0a0a',
        },
    },
    pink: {
        '500': '#FF383E',
    },
    interaction: {
        main: {
            default: '#CB0935',
            hover: '#AC0B2F',
            active: '#880D27',
        },
        sub: {
            default: '#171717',
            hover: '#0a0a0a',
            active: '#0a0a0a',
        },
        'main-subtle': {
            default: '#FBE9E9',
            hover: '#EFADAC',
            active: '#EA8181',
        },
        'sub-subtle': {
            default: '#E5E5E5',
            hover: '#D4D4D4',
            active: '#B0B0B0',
        },
        critical: {
            default: '#CB0935',
            hover: '#AC0B2F',
            active: '#880D27',
        },
        'critical-subtle': {
            default: '#FBE9E9',
            hover: '#EFADAC',
            active: '#EA8181',
        },
        warning: {
            default: '#FFDA68',
            hover: '#E2B73E',
            active: '#C4992A',
        },
        'warning-subtle': {
            default: '#fffae1',
            hover: '#FFDA68',
            active: '#E2B73E',
        },
        success: {
            default: '#0F796F',
            hover: '#0C5F57',
            active: '#0A524B',
        },
        'success-subtle': {
            default: '#E2EEED',
            hover: '#98CCC7',
            active: '#63B1AA',
        },
        neutral: {
            default: '#525252',
            hover: '#404040',
            active: '#262626',
        },
        'neutral-subtle': {
            default: '#E5E5E5',
            hover: '#D4D4D4',
            active: '#B0B0B0',
        },
        muted: {
            main: {
                hover: '#FEF7F7',
                active: '#FBE9E9',
            },
            sub: {
                hover: '#FAFAFA',
                active: '#E5E5E5',
            },
            critical: {
                hover: '#FEF7F7',
                active: '#FBE9E9',
            },
            neutral: {
                hover: '#FAFAFA',
                active: '#E5E5E5',
            },
        },
        tinted: {
            main: {
                hover: 'rgba(203, 9, 53, 0.04)',
                active: 'rgba(203, 9, 53, 0.12)',
            },
            sub: {
                hover: 'rgba(82, 82, 82, 0.04)',
                active: 'rgba(82, 82, 82, 0.12)',
            },
            critical: {
                hover: 'rgba(203, 9, 53, 0.04)',
                active: 'rgba(203, 9, 53, 0.12)',
            },
            neutral: {
                hover: 'rgba(0, 0, 0, 0.1)',
                active: 'rgba(0, 0, 0, 0.2)',
            },
            inverse: {
                hover: 'rgba(255, 255, 255, 0.1)',
                active: 'rgba(255, 255, 255, 0.2)',
            },
        },
        support: {
            unselected: '#B0B0B0',
            selected: '#525252',
            'unselected-strong': '#525252',
            disabled: '#E5E5E5',
            'disabled-content': '#B0B0B0',
            placeholder: '#737373',
        },
        links: {
            default: '#CB0935',
            hover: '#AC0B2F',
            'neutral-default': '#262626',
            'neutral-hover': '#0a0a0a',
            'inverse-default': '#ffffff',
            'inverse-hover': '#E5E5E5',
        },
    },
    grey: {
        '50': '#FAFAFA',
        '100': '#E5E5E5',
        '200': '#D4D4D4',
        '300': '#B0B0B0',
        '400': '#737373',
        '500': '#525252',
        '600': '#404040',
        '700': '#262626',
        '800': '#171717',
        '900': '#0a0a0a',
    },
    base: {
        divider: {
            subtle: '#FAFAFA',
            medium: '#E5E5E5',
            strong: '#B0B0B0',
            inverse: '#ffffff',
            brand: '#CB0935',
        },
        content: {
            default: '#525252',
            strong: '#262626',
            medium: '#737373',
            brand: '#CB0935',
            inverse: '#ffffff',
        },
        canvas: {
            default: '#ffffff',
            alt: '#FAFAFA',
            backdrop: '#E5E5E5',
            'brand-subtle': '#FEF7F7',
            inverse: '#171717',
            overlay: 'rgba(23, 23, 23, 0.8)',
        },
    },
    utility: {
        feedback: {
            info: '#171717',
            'info-subtle': '#FAFAFA',
            warning: '#FFDA68',
            'warning-subtle': '#fffae1',
            success: '#0F796F',
            'success-subtle': '#F5FAFA',
            critical: '#CB0935',
            'critical-subtle': '#FEF7F7',
        },
        'focus-default': '#171717',
        'focus-inverse': '#ffffff',
        'input-prefilled': '#fffae1',
        ui: '#ffffff',
        'ui-clear': 'rgba(255, 255, 255, 0)',
    },
    blue: {
        '50': '#F7F9FE',
        '100': '#E7EFFC',
        '200': '#ACC7FA',
        '300': '#82ABF7',
        '400': '#568DF4',
        '500': '#1361F0',
        '600': '#0D4FCA',
        '700': '#0B44AC',
        '800': '#093890',
        '900': '#072A69',
    },
    green: {
        '50': '#F5FAFA',
        '100': '#E2EEED',
        '200': '#98CCC7',
        '300': '#63B1AA',
        '400': '#2D978C',
        '500': '#0F796F',
        '600': '#0C5F57',
        '700': '#0A524B',
        '800': '#09463F',
        '900': '#073430',
    },
    yellow: {
        '50': '#fffae1',
        '100': '#FFDA68',
        '200': '#E2B73E',
        '300': '#C4992A',
        '400': '#A77C18',
        '500': '#8B6005',
        '600': '#704C00',
        '700': '#624200',
        '800': '#533800',
        '900': '#3f2b00',
    },
    slate: {
        '50': '#f8f9fa',
        '100': '#e9eaee',
        '200': '#babecb',
        '300': '#9aa0b3',
        '400': '#7b849c',
        '500': '#5d6785',
        '600': '#465173',
        '700': '#3c4764',
        '800': '#333c56',
        '900': '#272d41',
    },
    standard: {
        white: '#ffffff',
        black: '#000000',
    },
    skin: {
        '1base': '#FFCAA4',
        '1shadow': '#F5B896',
        '2base': '#EBA687',
        '2shadow': '#E29B7B',
        '3base': '#D98F6F',
        '3shadow': '#CE8361',
        '4base': '#BF7657',
        '4shadow': '#AF6D53',
        '5base': '#9F634F',
        '5shadow': '#8B5544',
        '6base': '#764738',
        '6shadow': '#633D32',
    },
};
