export const SETTINGS_ROUTES = {
    root: () => '/settings',
    profile: () => ({
        root: () => `${SETTINGS_ROUTES.root()}/profile`,
    }),
    paymentMethods: () => ({
        root: () => `${SETTINGS_ROUTES.root()}/payment-methods`,
        add: () => `${SETTINGS_ROUTES.paymentMethods().root()}/add`,
    }),
    notifications: () => ({
        root: () => `${SETTINGS_ROUTES.root()}/notifications`,
    }),
};
