/*
  DAER stands for detailed accounting entries report
 */
export const CURRENCY = 'SGD';
// hard coded for now; dynamic in the future
export const RESPECTIVE_COMPANY_ENTRIES = 'SportSG';
export const JOURNAL_SOURCE = 'Manual_Journal';
export const COMPANY_CODE = 'SSC';
export const WORKDAY_FUND = 'SSC_FND0001';
export const TAX_TYPE = 'TAX_COLLECTED';
export const TAX_CODE = 'SGP_9%_GST';
export const TAX_RATE = 'SGP_090_GST';
export const TAX_APPLICABILITY = 'SGP_Sales';
export const DAER_HEADERS = {
    headerKey: { title: 'Header Key', mandatory: true },
    lineKey: { title: 'Line Key', mandatory: true },
    transactionId: { title: 'Transaction ID', mandatory: true },
    transactionType: { title: 'Transaction Type', mandatory: true },
    transactionDate: { title: 'Transaction Date', mandatory: true },
    receiptId: { title: 'Receipt ID', mandatory: false },
    accountingTime: { title: 'Accounting Time', mandatory: true },
    accountingDate: { title: 'Accounting Date', mandatory: true },
    ledgerAccount: { title: 'Ledger Account', mandatory: true },
    debitAmount: { title: 'Debit Amount', mandatory: false },
    creditAmount: { title: 'Credit Amount', mandatory: false },
    currency: { title: 'Currency', mandatory: true },
    entity: { title: 'Entity', mandatory: true },
    entityId: { title: 'Entity ID', mandatory: true },
    entityStatus: { title: 'Entity Status', mandatory: true },
    stripeCharge: { title: 'Stripe Charge', mandatory: false },
    stripePayout: { title: 'Stripe Payout', mandatory: false },
    stripeRefund: { title: 'Stripe Refund', mandatory: false },
    respectiveCompanyEntries: {
        title: "Respective Company's Entries",
        mandatory: true,
    },
    memberName: { title: 'Member Name', mandatory: false },
    venueName: { title: 'Venue Name', mandatory: true },
    venueType: { title: 'Venue Type', mandatory: true },
    memberId: { title: 'Member ID', mandatory: false },
    usageDate: { title: 'Usage Date', mandatory: false },
    paymentMode: { title: 'Payment Mode', mandatory: false },
    interfaceType: { title: 'Interface Type', mandatory: true },
    journalSource: { title: 'Journal Source', mandatory: true },
    workdayExternalReferenceId: {
        title: 'Workday External Reference ID',
        mandatory: true,
    },
    companyCode: { title: 'Company Code', mandatory: true },
    workdayLedgerId: { title: 'Workday Ledger', mandatory: true },
    transactionMemo: { title: 'Transaction Memo', mandatory: true },
    workdayCostCentre: { title: 'Workday Cost Centre', mandatory: true },
    workdayProgram: { title: 'Workday Program', mandatory: true },
    workdayFund: { title: 'Workday Fund', mandatory: true },
    workdaySpendId: {
        title: 'Workday Spend Category',
        mandatory: false,
    },
    workdayRevenueId: {
        title: 'Workday Revenue Category',
        mandatory: false,
    },
    // not in April launch
    // workdayGrant: { title: 'Workday Grant', mandatory: false },
    // not in April launch
    // workdayRelatedParties: { title: 'Workday Related Parties', mandatory: false },
    workdayLocation: { title: 'Workday Location', mandatory: true },
    workdaySalesItemReferenceId: {
        title: 'Workday Sales Item',
        mandatory: false,
    },
    taxDate: { title: 'Tax Date', mandatory: false },
    taxType: { title: 'Tax Type', mandatory: false },
    taxAmount: { title: 'Tax Amount', mandatory: false },
    taxCode: { title: 'Tax Code', mandatory: false },
    taxRate: { title: 'Tax Rate', mandatory: false },
    taxApplicability: { title: 'Tax Applicability', mandatory: false },
};
export const OUTPUT_GST_REVENUE_TAGS = {
    workdayRevenueId: '983101RC',
    workdayRevenueCategory: 'GST Output Tax',
    workdaySalesItemReferenceId: null,
    workdaySalesItemDescription: null,
};
export const NULL_REVENUE_TAGS = {
    workdayRevenueId: null,
    workdayRevenueCategory: null,
    workdaySalesItemReferenceId: null,
    workdaySalesItemDescription: null,
};
export const STRIPE_CHARGES_SPEND_TAGS = {
    workdaySpendId: '216304SC',
    workdaySpendCategory: 'Bank Processing Fees for e-Payments',
};
export const NULL_SPEND_TAGS = {
    workdaySpendId: null,
    workdaySpendCategory: null,
};
/* FOR BOOKINGS */
export const BOOKING_DEFERRED_REVENUE_TAGS = {
    workdayRevenueId: '982199RC',
    workdayRevenueCategory: 'Other Deferred Revenue',
    workdaySalesItemReferenceId: 'SSC_Deferred_Revenue_6',
    workdaySalesItemDescription: 'MMS - Deferred Revenue - Hiring of Facilities',
};
export const BOOKINGS_REVENUE_TAGS = {
    workdayRevenueId: 'C49104RC',
    workdayRevenueCategory: 'Rental Income:Facilities',
    workdaySalesItemReferenceId: 'SSC_Hiring_of_Facilities_1',
    workdaySalesItemDescription: 'Hiring of Facilities - Non-exclusive',
};
/* FOR PASSES */
// hard-coded for all passes
// same value for OGP test venues
export const MMS_OFFICE_AND_ANALYTICS_WORKTAGS = {
    workdayLocation: 'LOC_21458',
    workdayCostCentre: 'SSC_CC0089',
    workdayProgram: 'SSC_PR0634',
};
/* FOR PROGRAMMES */
export const LTP_PROGRAMME_DEFERRED_REVENUE = {
    workdayRevenueId: '982199RC',
    workdaySalesItemReferenceId: 'SSC_Deferred_Revenue_7',
    workdaySalesItemDescription: 'MMS - Deferred Revenue - LTP',
    workdayRevenueCategory: 'Other Deferred Revenue',
};
export const NON_LTP_PROGRAMME_DEFERRED_REVENUE = {
    workdayRevenueId: '982199RC',
    workdaySalesItemReferenceId: 'SSC_Deferred_Revenue_8',
    workdaySalesItemDescription: 'MMS - Deferred Revenue - Non-LTP',
    workdayRevenueCategory: 'Other Deferred Revenue',
};
// used for both LTP and non-LTP programmes; it is a limit of current accounting system
export const LTP_PROGRAMME_REVENUE = {
    workdayRevenueId: 'C20607RC',
    workdaySalesItemReferenceId: 'SSC_Programme_Fees_1',
    workdaySalesItemDescription: 'Learn To Play (LTP) Programme Fees',
    workdayRevenueCategory: 'MCCY Services Rendered - Course fees',
};
