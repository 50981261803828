export const PASS_AGE_GROUP = ['Student', 'Child', 'Adult', 'Senior'];
export const PassAgeGroupTypes = [
    'Student',
    'Child',
    'Adult',
    'Senior',
];
export const isPassAgeGroup = (x) => {
    return PASS_AGE_GROUP.includes(x);
};
export const AGE_COMPARATOR_MAP = {
    Child: 1,
    Student: 2,
    Adult: 3,
    Senior: 4,
};
