export enum OtpMachineEvent {
  ResetToIdle = 'RESET_TO_IDLE',
  SendOtpSuccess = 'SEND_OTP_SUCCESS',
  VerifyOtpSuccess = 'VERIFY_OTP_SUCCESS',
}

export enum OtpMachineState {
  Idle = 'idle',
  Verifying = 'verifying',
  Verified = 'verified',
}

export const defaultOtpState = OtpMachineState.Idle

export const otpStateReducer = (
  state: OtpMachineState,
  event: OtpMachineEvent,
) => {
  switch (event) {
    case OtpMachineEvent.ResetToIdle: {
      return OtpMachineState.Idle
    }
    case OtpMachineEvent.SendOtpSuccess: {
      if (state === OtpMachineState.Idle) {
        return OtpMachineState.Verifying
      }
      return state
    }
    case OtpMachineEvent.VerifyOtpSuccess: {
      if (state === OtpMachineState.Verifying) {
        return OtpMachineState.Verified
      }
      return state
    }
    default:
      return state
  }
}
