export const ADD_ONLY = 'Y';
export const BANK_ACCOUNT = 'BA_SSC_DBS_BANK_MMS';
export const INTERFACE_TYPE_AHBT = 'AHBT';
export const AHBT_SHEET_NAME = 'Submit Ad hoc Bank Transaction';
export const AHBT_DATA = {
    spreadSheetKey: { title: 'Spreadsheet Key*', mandatory: true },
    addOnly: { title: 'Add Only', mandatory: true },
    adHocBankTransaction: { title: 'Ad hoc Bank Transaction', mandatory: false },
    adHocBankTransactionId: {
        title: 'Ad hoc Bank Transaction ID',
        mandatory: true,
    },
    adHocBankTransactionNumber: {
        title: 'Ad Hoc Bank Transaction Number',
        mandatory: false,
    },
    transactionDate: { title: 'Transaction Date*', mandatory: true },
    transactionMemo: { title: 'Transaction Memo*', mandatory: true },
    company: { title: 'Company*', mandatory: true },
    bankAccount: { title: 'Bank Account*', mandatory: true },
    transactionAmount: { title: 'Transaction Amount*', mandatory: true },
    deposit: { title: 'Deposit*', mandatory: true },
    withdrawal: { title: 'Withdrawal*', mandatory: true },
    adHocBankTransactionPurpose: {
        title: 'Ad hoc Bank Transaction Purpose',
        mandatory: false,
    },
    transactionId: { title: 'Transaction ID', mandatory: false },
    // Stripe payout ID
    externalReference: { title: 'External Reference', mandatory: true },
    // columns Q onward; unique for each row
    rowId: { title: 'Row ID*', mandatory: false },
    intercompanyAffiliate: { title: 'Intercompany Affiliate', mandatory: false },
    resourceCategory: { title: 'Resource Category', mandatory: false },
    revenueCategory: { title: 'Revenue Category', mandatory: false },
    ledgerAccount: { title: 'Ledger Account', mandatory: true },
    accountSet: { title: 'Account Set', mandatory: true },
    lineAmount: { title: 'Line Amount*', mandatory: true },
    taxApplicability: { title: 'Tax Applicability', mandatory: false },
    taxCode: { title: 'Tax Code', mandatory: false },
    lineMemo: { title: 'Line Memo', mandatory: true },
    businessUnit: { title: 'Business Unit', mandatory: false },
    customer: { title: 'Customer', mandatory: false },
    financialInstruments: { title: 'Financial Instruments', mandatory: false },
    grant: { title: 'Grant', mandatory: false },
    relatedParties: { title: 'Related Parties', mandatory: false },
    supplier: { title: 'Supplier', mandatory: false },
    costCenter: { title: 'Cost Center', mandatory: true },
    fund: { title: 'Fund', mandatory: true },
    project: { title: 'Project', mandatory: false },
    program: { title: 'Program', mandatory: true },
    salesItem: { title: 'Sales Item', mandatory: false },
    location: { title: 'Location', mandatory: true },
    fundInterworktagAffiliate: {
        title: 'Fund Interworktag Affiliate',
        mandatory: false,
    },
};
