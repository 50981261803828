export function toTitleCase(str) {
    return str.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
export function toSmartLowerCase(str) {
    return str.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
        if (txt.toUpperCase() === txt) {
            return txt;
        }
        return txt.toLowerCase();
    });
}
