import { useMemo } from 'react';
import { ListItem, OrderedList, Text, UnorderedList, } from '@chakra-ui/react';
import { Link } from '@opengovsg/design-system-react';
export const useMarkdownComponents = ({ styles = {}, overrides = {}, } = {}) => {
    const textStyles = useMemo(() => ({
        sx: {
            whiteSpace: 'pre-wrap',
            ...(styles.text ?? {}),
        },
    }), [styles.text]);
    const linkStyles = useMemo(() => ({
        sx: {
            whiteSpace: 'pre-wrap',
            display: 'initial',
            ...(styles.link ?? {}),
        },
    }), [styles.link]);
    const listStyles = useMemo(() => ({
        sx: {
            whiteSpace: 'pre-wrap',
            ...(styles.list ?? {}),
        },
    }), [styles.list]);
    const mdComponents = useMemo(() => ({
        ol: ({ node, ...props }) => (<OrderedList marginInlineStart="1.25rem" whiteSpace="initial" {...props} {...textStyles}/>),
        ul: ({ node, ...props }) => <UnorderedList {...props} {...listStyles}/>,
        li: ({ node, ...props }) => <ListItem {...props} {...textStyles}/>,
        a: ({ node, ...props }) => {
            const { href } = props;
            const isExternal = (href && !href.startsWith(window.location.origin)) || false;
            return <Link {...props} isExternal={isExternal} {...linkStyles}/>;
        },
        p: ({ node, ...props }) => <Text {...props} {...textStyles}/>,
        ...overrides,
    }), [linkStyles, overrides, textStyles, listStyles]);
    return mdComponents;
};
