import { useEffect } from 'react'

import { ModalContent, ModalOverlay } from '@chakra-ui/react'

import { ResponsiveModal } from '@activesg/ui/components'

import { useIsImitatingUser } from '~/components/Header/HeaderMenu/ImitateUser/useIsImitatingUser'

import { VerifyMobileProvider } from '../VerifyMobileProvider'
import { AcceptTermsAndConditionsContent } from './AcceptTermsAndConditionsContent'
import { useUserOnboarding } from './UserOnboardingModalContext'
import { VerifyMobileContent } from './VerifyMobileContent'

const AutoClose = () => {
  const { onClose } = useUserOnboarding()

  useEffect(() => {
    onClose()
  }, [onClose])

  return null
}

export const UserOnboardingModal = (): JSX.Element => {
  const { hasAcceptedTermsAndConditions, hasMobile, isOpen, onClose } =
    useUserOnboarding()

  const isImitatingUser = useIsImitatingUser()

  return (
    <ResponsiveModal
      // Only for UAT tests will these be allowed to be closable
      closeOnEsc={isImitatingUser ? undefined : false}
      closeOnOverlayClick={isImitatingUser ? undefined : false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        {!hasAcceptedTermsAndConditions ? (
          <AcceptTermsAndConditionsContent />
        ) : !hasMobile ? (
          <VerifyMobileProvider>
            <VerifyMobileContent />
          </VerifyMobileProvider>
        ) : (
          <AutoClose />
        )}
      </ModalContent>
    </ResponsiveModal>
  )
}
