/**
 * Do not edit ./generatedTypes and ./generatedEnums files directly.
 * They are auto generated by kysely-prisma.
 */
var _a;
import { 
// eslint-disable-next-line no-restricted-imports
Kysely as NativeKysely, } from 'kysely';
export * from './augmented-types.js';
export * from './generated-enums.js';
export * as Model from './generated-types.js';
export * from './helpers.js';
export * from './selectable-types.js';
export { sql } from 'kysely';
export class Kysely extends NativeKysely {
    static #identifier = Symbol();
    // eslint-disable-next-line no-unused-private-class-members
    #identity() {
        return (_a).#identifier;
    }
}
_a = Kysely;
