import { useContext } from 'react'

import { VerifyMobileContext } from './VerifyMobileContext'

export const useVerifyMobile = () => {
  const context = useContext(VerifyMobileContext)
  if (!context) {
    throw new Error(
      'useVerifyMobile must be used within a VerifyMobileProvider component',
    )
  }
  return context
}
