import { useCallback, useEffect, useState } from 'react';
import { Button, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, } from '@chakra-ui/react';
import { Banner, ModalCloseButton } from '@opengovsg/design-system-react';
import { BiRefresh } from 'react-icons/bi';
import { REQUIRE_UPDATE_EVENT } from '@activesg/constants';
import { ResponsiveModal } from './ResponsiveModal';
export const AppVersionModalBanner = () => {
    const [requireUpdate, setRequireUpdate] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const handleRequireUpdateEvent = useCallback(() => {
        setRequireUpdate(true);
    }, []);
    const onRefresh = useCallback(() => {
        window.location.reload();
    }, []);
    useEffect(() => {
        window.addEventListener(REQUIRE_UPDATE_EVENT, handleRequireUpdateEvent);
        return () => {
            window.removeEventListener(REQUIRE_UPDATE_EVENT, handleRequireUpdateEvent);
        };
    }, [handleRequireUpdateEvent]);
    useEffect(() => {
        requireUpdate && onOpen();
    }, [onOpen, requireUpdate]);
    return (<AppVersionModalBannerView isOpen={isOpen} requireUpdate={requireUpdate} onClose={onClose} onRefresh={onRefresh}/>);
};
export const AppVersionModalBannerView = ({ isOpen, onClose, onRefresh, requireUpdate, }) => {
    return (<>
      <ResponsiveModal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Update Available</ModalHeader>
          <ModalBody>
            Please refresh the page to get the latest version.
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="neutral" leftIcon={<BiRefresh />} variant="clear" w="full" onClick={onRefresh}>
              Refresh
            </Button>
          </ModalFooter>
        </ModalContent>
      </ResponsiveModal>
      {requireUpdate && (<Banner variant="warn">
          An update is available. Please refresh the page.
        </Banner>)}
    </>);
};
