import Image from 'next/image';
import NextLink from 'next/link';
import { Link, Text } from '@chakra-ui/react';
export const RestrictedMiniFooter = () => {
    return (<Text alignItems="center" display="flex" fontSize="0.625rem" fontWeight={500} letterSpacing="0.08em" lineHeight="1rem" textTransform="uppercase" whiteSpace="pre">
      Built by{' '}
      <Link as={NextLink} href="https://open.gov.sg" title="To OGP homepage">
        <Image priority alt="OGP Logo" height={12} src="/assets/restricted-ogp-logo-full.svg" width={233}/>
      </Link>
    </Text>);
};
