import NextLink from 'next/link';
import { Icon } from '@chakra-ui/react';
import { Link } from '@opengovsg/design-system-react';
import { BiLeftArrowAlt } from 'react-icons/bi';
export const BackBannerLink = ({ children, ...props }) => {
    return (<Link textStyle="subhead-2" variant="standalone" {...props} as={NextLink}>
      <Icon aria-hidden as={BiLeftArrowAlt} fontSize="1.25rem" mr="0.25rem"/>
      {children}
    </Link>);
};
