import React from 'react';
import { Icon as ChakraIcon, HStack, Stack, Text, } from '@chakra-ui/react';
export const Paper = ({ children, ...props }) => {
    return (<Stack bg="base.canvas.default" borderColor="base.divider.medium" borderRadius="0.5rem" borderWidth="1px" maxW="100vw" p="1.5rem" spacing="1rem" w="full" {...props}>
      {children}
    </Stack>);
};
export const FormPaper = ({ children }) => {
    return <Paper w="42.5rem">{children}</Paper>;
};
export const FormPaperSectionHeader = ({ Icon, children, }) => {
    return (<HStack spacing="1rem">
      {Icon && <ChakraIcon as={Icon} fontSize="1.5rem"/>}
      <Text textStyle="subhead-3">{children}</Text>
    </HStack>);
};
