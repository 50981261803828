import { createContext, useCallback } from 'react';
import { LOGGED_IN_KEY } from '@activesg/constants';
import { useLocalStorage } from '../../../hooks';
// Exported for testing.
export const LoginStateContext = createContext(null);
/**
 * Provider component that wraps your app and makes client login state boolean available
 * to any child component that calls `useLoginState()`.
 */
export const LoginStateProvider = ({ children }) => {
    const loginState = useProvideLoginState();
    return (<LoginStateContext.Provider value={loginState}>
      {children}
    </LoginStateContext.Provider>);
};
const useProvideLoginState = () => {
    const [hasLoginStateFlag, setLoginStateFlag] = useLocalStorage(LOGGED_IN_KEY, undefined);
    const setHasLoginStateFlag = useCallback(() => {
        setLoginStateFlag(true);
    }, [setLoginStateFlag]);
    const removeLoginStateFlag = useCallback(() => {
        setLoginStateFlag(undefined);
    }, [setLoginStateFlag]);
    return {
        hasLoginStateFlag: !!hasLoginStateFlag,
        setHasLoginStateFlag,
        removeLoginStateFlag,
    };
};
