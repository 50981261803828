import { createElement } from 'react';
import { createStandaloneToast, } from '@chakra-ui/react';
import { Toast } from '@opengovsg/design-system-react';
import { theme } from '../theme';
const result = createStandaloneToast({
    theme,
    defaultOptions: {
        position: 'top',
        render: (props) => {
            return createElement(Toast, props);
        },
    },
});
// We need to explicitly cast the return because chakra types are not properly exported
// eslint-disable-next-line
const toast = result.toast;
const ToastContainer = result.ToastContainer;
export { toast, ToastContainer };
