export const KEY_DELIMITER = ':';
export const KEYSPACE_PREFIXES = {
    CACHE: 'CACHE',
    RATE_LIMIT: 'RATE_LIMIT',
};
export const CACHE_ENTITIES = {
    TIMESLOTS: 'TIMESLOTS',
    POSTAL_CODES: 'POSTAL_CODES',
    COMPOUND_VENUES: 'COMPOUND_VENUES',
    PUBLIC_HOLIDAYS: 'PUBLIC_HOLIDAYS',
    AVAILABILITY_SUMMARY: 'AVAILABILITY_SUMMARY',
    POPULARITY_INDICATOR: 'POPULARITY_INDICATOR',
    GYM_CAPACITY: 'GYM_CAPACITY',
    PROGRAMME_VENUES: 'PROGRAMME_VENUES',
};
export const CACHE_KEYS_SPECIFER = {
    PROGRAMME_VENUES: ['venueId'],
    TIMESLOTS: ['venueId', 'activityId', 'date'],
    POSTAL_CODES: ['postalCode'],
    COMPOUND_VENUES: ['venueId'],
    PUBLIC_HOLIDAYS: ['year'],
    AVAILABILITY_SUMMARY: ['venueId', 'activityId', 'membershipIds'],
    POPULARITY_INDICATOR: ['venueId', 'activityId', 'timestamp'],
    GYM_CAPACITY: ['gym'],
};
