import bigInt from 'big-integer';
import { partition } from 'lodash-es';
import { splitEquallyAndBackloadRemainder } from '../big-int/index.js';
import { sortPaymentItems } from '../payment.js';
export function assignCreditsToItems(items, credits, logger = console) {
    const sortedItems = sortPaymentItems(items);
    const total = items.reduce((sum, item) => sum.add(bigInt(item.priceInCents)), bigInt(0));
    const totalDiscountInCents = items.reduce((acc, item) => acc.add(bigInt(item.discountInCents ?? '0')), bigInt(0));
    // We actually know individualItems does not contain the collection key, but we don't need that information.
    const [collectionItems, individualItems] = partition(sortedItems, (item) => 'collection' in item);
    const collectionKeys = new Set();
    for (const item of collectionItems) {
        collectionKeys.add(item.collection);
    }
    const processed = individualItems.map((item) => ({
        ...item,
        // TODO: Check if we should round up or down for the credits usable.
        creditsUsable: bigInt(item.priceInCents)
            .multiply(bigInt(item.activeSgCreditUsageLimit))
            .divide(bigInt(100)),
    }));
    const collections = [];
    for (const collectionKey of collectionKeys) {
        const items = collectionItems.filter((item) => item.collection === collectionKey);
        if (items[0] === undefined) {
            continue;
        }
        // FIXME: Ideally we log an error here if the activeSgCreditUsageLimits are different within a single collection.
        const activeSgCreditUsageLimit = items
            .map(({ activeSgCreditUsageLimit }) => bigInt(activeSgCreditUsageLimit))
            .reduce((min, item) => bigInt.min(min, item));
        const sum = items.reduce((sum, item) => sum.add(bigInt(item.priceInCents)), bigInt(0));
        const creditsUsable = bigInt(sum)
            .multiply(bigInt(activeSgCreditUsageLimit))
            .divide(bigInt(100));
        // Collection key only used for discriminating between collections and normal items
        collections.push({
            items,
            activeSgCreditUsageLimit,
            creditsUsable,
            collectionKey,
        });
    }
    // We always allocate credits to collections first, then individual items.
    const combined = [...collections, ...sortPaymentItems(processed)];
    const creditsUsableTotal = combined.reduce((acc, item) => acc.add(item.creditsUsable), bigInt(0));
    const creditsUsed = calculateUsableCredits(bigInt.min(credits, creditsUsableTotal), total);
    let creditsToDistribute = creditsUsed;
    const itemsWithCredits = [];
    for (const { creditsUsable, ...item } of combined) {
        const creditsToUse = bigInt.min(creditsUsable, creditsToDistribute);
        if ('collectionKey' in item) {
            const { items } = distributeCreditsToBreakdownItems(sortPaymentItems(item.items), creditsToUse, logger);
            itemsWithCredits.push(...items);
        }
        else {
            itemsWithCredits.push({
                ...item,
                creditsUsedInCents: creditsToUse.equals(bigInt(0))
                    ? null
                    : creditsToUse.toString(),
            });
        }
        creditsToDistribute = creditsToDistribute.minus(creditsToUse);
    }
    const cash = total.minus(creditsUsed);
    return {
        items: itemsWithCredits,
        creditsTotal: creditsUsed,
        totalDiscountInCents,
        cashTotal: cash,
        total,
    };
}
// Properly distributes ActiveSG credits across multiple sub-items, while maintaining correct total sum.
function distributeCreditsToBreakdownItems(items, credits, logger) {
    let creditsToDistribute = credits;
    const processed = items.map((item) => ({
        ...item,
        // TODO: Check if we should round up or down for the credits usable.
        creditsUsable: bigInt(item.priceInCents)
            .multiply(bigInt(item.activeSgCreditUsageLimit))
            .divide(bigInt(100)),
    }));
    let itemsWithCredits = [];
    for (const { creditsUsable, ...item } of processed) {
        const creditsUsed = bigInt.min(creditsUsable, creditsToDistribute);
        itemsWithCredits.push({
            ...item,
            creditsUsedInCents: creditsUsed.equals(bigInt(0))
                ? null
                : creditsUsed.toString(),
        });
        creditsToDistribute = creditsToDistribute.minus(creditsUsed);
    }
    // For safety, we try-catch the redistribution algorithm.
    // If any error is thrown by splitEquallyAndBackloadRemainder, we log and error but use the original distribution
    try {
        if (creditsToDistribute.notEquals(bigInt(0))) {
            const redistributedItemsWithCredits = [];
            const roundedValueItemCount = itemsWithCredits.filter((item) => {
                const { remainder } = bigInt(item.priceInCents)
                    .multiply(item.activeSgCreditUsageLimit)
                    .divmod(bigInt(100));
                return remainder.notEquals(0);
            }).length;
            const { nonLastSplitAmount, lastSplitAmount } = splitEquallyAndBackloadRemainder(creditsToDistribute, bigInt(roundedValueItemCount));
            let distributed = 0;
            for (const item of itemsWithCredits) {
                if (bigInt(item.priceInCents)
                    .multiply(item.activeSgCreditUsageLimit)
                    .divmod(bigInt(100))
                    .remainder.notEquals(bigInt(0))) {
                    const amountToAdd = distributed === roundedValueItemCount - 1
                        ? lastSplitAmount
                        : nonLastSplitAmount;
                    redistributedItemsWithCredits.push({
                        ...item,
                        creditsUsedInCents: item.creditsUsedInCents
                            ? bigInt(item.creditsUsedInCents).add(amountToAdd).toString()
                            : amountToAdd.toString(),
                    });
                }
                else {
                    redistributedItemsWithCredits.push(item);
                }
                distributed++;
            }
            // We reassign only at the end so that if any error throws, the original array is kept untouched.
            itemsWithCredits = redistributedItemsWithCredits;
        }
    }
    catch (e) {
        logger.warn({
            message: 'Failed to distribute credits evenly.',
            action: 'distribute-credits-breakdown',
            error: e,
        });
    }
    const creditsTotal = itemsWithCredits.reduce((acc, item) => item.creditsUsedInCents ? acc.add(bigInt(item.creditsUsedInCents)) : acc, bigInt(0));
    if (creditsTotal.notEquals(credits)) {
        logger.warn({
            message: 'Failed to distribute credits evenly.',
            action: 'distribute-credits-breakdown',
            context: {
                itemsWithCredits,
                creditsTotal,
                credits,
            },
        });
    }
    return {
        items: itemsWithCredits,
    };
}
/**
 * @deprecated Use `assignCreditsToItems` instead
 *
 * @internal
 */
export const calculateUsableCredits = (credits, amountToPay) => {
    // ActiveSG$ is only usable up till 50 cents remaining
    const originalBintToDeduct = bigInt.min(credits, amountToPay);
    const minAmountToPay = amountToPay.minus(originalBintToDeduct);
    if (minAmountToPay.greater(bigInt(0)) && minAmountToPay.lesser(bigInt(50))) {
        return amountToPay.minus(bigInt(50));
    }
    else {
        return originalBintToDeduct;
    }
};
