import { type PropsWithChildren } from 'react'

import {
  useProvideVerifyMobileContext,
  VerifyMobileContext,
} from './VerifyMobileContext'

export const VerifyMobileProvider = ({ children }: PropsWithChildren) => {
  const values = useProvideVerifyMobileContext()

  return (
    <VerifyMobileContext.Provider value={values}>
      {children}
    </VerifyMobileContext.Provider>
  )
}
