import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { env } from '@activesg/env';
export const initMonitoring = ({ service }) => {
    datadogLogs.init({
        clientToken: env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
        env: env.NEXT_PUBLIC_ENVIRONMENT,
        site: 'datadoghq.com',
        service,
        version: env.NEXT_PUBLIC_APP_VERSION,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ['log', 'info', 'warn', 'error'],
        sessionSampleRate: 10,
        beforeSend: (event) => {
            if (!event.message.startsWith('%c <<'))
                return true;
            event.message = event.message.replace(/^%c << ([A-Za-z]*) #\d+ %c([A-Za-z0-9.]*)%c %O \n*([\W\S]*)/, '$1 $2\n $3');
            event.message = event.message.replace(/ *(; )?(background-color|color|padding|font-weight): [#\w]+;\s+/g, '');
            try {
                /* eslint-disable no-restricted-syntax, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment */
                const [msg, o] = event.message.split(/\n(.*)/s);
                if (!msg || !o)
                    return true;
                const [action, path] = msg.split(/ (.*)/s);
                if (!action || !path)
                    return true;
                const obj = JSON.parse(o);
                if (!obj)
                    return true;
                obj.action = action;
                obj.path = path;
                obj.message = obj.result?.shape?.message ?? msg;
                event.message = JSON.stringify(obj);
                /* eslint-enable no-restricted-syntax, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment */
            }
            catch (_) {
                return true;
            }
            return true;
        },
    });
    datadogRum.init({
        applicationId: env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
        clientToken: env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service,
        version: env.NEXT_PUBLIC_APP_VERSION,
        env: env.NEXT_PUBLIC_ENVIRONMENT,
        sessionSampleRate: 10,
        sessionReplaySampleRate: 10,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [/https:\/\/.*activesg(-(uat|staging))\.gov\.sg/],
    });
    datadogRum.startSessionReplayRecording();
};
export const setMonitoringUserContext = ({ id }) => {
    datadogLogs.setUser({
        id,
    });
    datadogRum.setUser({
        id,
    });
};
