export function sortPaymentItems(items) {
    return items.sort((lhs, rhs) => {
        // If there is no title, compare the description.
        if (!lhs.title && !rhs.title) {
            return lhs.description.localeCompare(rhs.description, 'en', {
                numeric: true,
                sensitivity: 'base',
            });
        }
        // If there are titles, we compare the titles, with items with titles coming first.
        if (!lhs.title) {
            return 1;
        }
        if (!rhs.title) {
            return -1;
        }
        // If both have titles, we compare the titles first, then the descriptions.
        const titleCompared = lhs.title.localeCompare(rhs.title, 'en', {
            numeric: true,
            sensitivity: 'base',
        });
        if (titleCompared !== 0) {
            return titleCompared;
        }
        return lhs.description.localeCompare(rhs.description, 'en', {
            numeric: true,
            sensitivity: 'base',
        });
    });
}
