import { HStack, Stack, Text } from '@chakra-ui/react';
import { displayCurrency, toTitleCase } from '@activesg/common/utilities';
export function PaymentReceiptCreditUsage({ creditUsages }) {
    return (<Stack color="base.content.medium" spacing="0.25rem" textStyle="body-2">
      <Text>ActiveSG$</Text>
      <Stack spacing="0.25rem">
        {creditUsages.map(({ id, name, totalCreditsUsedInCents }) => {
            return (<HStack key={id} alignItems="start" justifyContent="space-between" spacing="1rem">
              <Text>{name ? toTitleCase(name) : 'Used'}</Text>
              <Text minW="fit-content">{`-S$${displayCurrency(totalCreditsUsedInCents)}`}</Text>
            </HStack>);
        })}
      </Stack>
    </Stack>);
}
export function PaymentDetailsCreditUsage({ creditUsages }) {
    return (<Stack spacing="0.5rem">
      <Text textStyle="subhead-2">ActiveSG$ used</Text>
      <Stack spacing="0.25rem">
        {creditUsages.map(({ id, name, totalCreditsUsedInCents }) => {
            return (<HStack key={id} alignItems="start" justifyContent="space-between" spacing="1rem">
              <Text color="base.content.default" textStyle="body-2">
                {name ? toTitleCase(name) : '\u00A0'}
              </Text>
              <Text color="utility.feedback.success" minW="fit-content" textStyle="subhead-2">{`-S$${displayCurrency(totalCreditsUsedInCents)}`}</Text>
            </HStack>);
        })}
      </Stack>
    </Stack>);
}
