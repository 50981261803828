import { merge } from 'lodash-es';
import { textStyles as foundation } from './foundations/textStyles';
const customTextStyles = {
    'h3-semibold': {
        fontWeight: 600,
        lineHeight: '2.25rem',
        fontSize: '1.75rem',
        letterSpacing: '-0.019em',
        fontFamily: 'body',
    },
    'calendar-event-title': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '0.5625rem',
        lineHeight: '0.625rem',
        color: 'gray.900',
        fontWeight: '600',
    },
    'calendar-event-subtitle': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '0.5625rem',
        lineHeight: '0.5625rem',
        color: 'gray.700',
        fontWeight: '400',
    },
    'subhead-2': {
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        letterSpacing: '0',
        fontFamily: 'body',
    },
    link: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '1.25rem',
        letterSpacing: '-0.00525rem',
        textDecorationLine: 'underline',
    },
};
export const textStyles = merge(customTextStyles, foundation);
