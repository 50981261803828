import { Banner as BannerComponent } from '@opengovsg/design-system-react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { useMarkdownComponents } from '../hooks/useMarkdownComponents';
import { useFeatureValue } from '../libraries/growthbook';
export const AppBanner = () => {
    const banner = useFeatureValue('banner', null);
    // FIXME: Colors for links
    const components = useMarkdownComponents({
        styles: {
            link: {
                color: banner?.variant === 'warn'
                    ? 'base.content.strong'
                    : 'base.content.inverse',
                _hover: {
                    color: banner?.variant === 'warn' ? 'yellow.500' : 'grey.100',
                },
                _active: {
                    color: banner?.variant === 'warn' ? 'yellow.400' : 'grey.200',
                },
            },
        },
    });
    if (banner === null) {
        return null;
    }
    const { message, variant } = banner;
    return (<BannerComponent key={message} variant={variant}>
      <ReactMarkdown components={components} remarkPlugins={[gfm]}>
        {message}
      </ReactMarkdown>
    </BannerComponent>);
};
