import { Period } from '@activesg/db';
import { assertUnreachable } from './asserts.js';
export const toUnitType = (period) => {
    switch (period) {
        case Period.DAY:
            return 'day';
        case Period.MONTH:
            return 'month';
        case Period.WEEK:
            return 'isoWeek';
        default:
            return assertUnreachable(period);
    }
};
export const rankPeriod = (period) => {
    switch (period) {
        case Period.DAY:
            return 1;
        case Period.WEEK:
            return 2;
        case Period.MONTH:
            return 3;
        default:
            return assertUnreachable(period);
    }
};
export const renderPeriod = (period) => {
    switch (period) {
        case Period.DAY:
            return 'day';
        case Period.WEEK:
            return 'week';
        case Period.MONTH:
            return 'month';
        default:
            return assertUnreachable(period);
    }
};
