import { z } from 'zod';
import { CUSTOM_ERROR_CODE, } from '@activesg/constants';
const CUSTOM_ERROR_CODE_KEYS = Object.keys(CUSTOM_ERROR_CODE);
export const TRPCWithErrorCodeSchema = z
    .object({
    data: z.object({ code: z.enum(CUSTOM_ERROR_CODE_KEYS) }),
})
    .transform((data) => data.data.code);
export class CustomError extends Error {
    code;
    context;
    location;
    cause;
    constructor({ context, location, code, ...opts }) {
        const cause = getCauseFromUnknown(opts.cause);
        const message = opts.message ?? cause?.message ?? code;
        super(message, { cause });
        this.name = this.constructor.name;
        this.code = code;
        this.context = context;
        this.location = location;
    }
}
class SyntheticError extends Error {
}
function isObject(value) {
    // check that value is object
    return !!value && !Array.isArray(value) && typeof value === 'object';
}
// Adapted from tRPC error handling
export function getCauseFromUnknown(cause) {
    if (cause instanceof Error) {
        return cause;
    }
    const type = typeof cause;
    if (type === 'undefined' || type === 'function' || cause === null) {
        return undefined;
    }
    // Primitive types just get wrapped in an error
    if (type !== 'object') {
        return new Error(String(cause));
    }
    // If it's an object, we'll create a synthetic error
    if (isObject(cause)) {
        const err = new SyntheticError();
        for (const key in cause) {
            err[key] = cause[key];
        }
        return err;
    }
    return undefined;
}
