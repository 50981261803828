// Errors for mobile number verification
export const MOBILE_VFN = {
  sameAsExisting: 'Mobile number is same as existing number',
  unknown: 'Error sending verification sms',
  userNotFound: 'User not found',
}

// Errors for email verification
export const EMAIL_VFN = {
  sameAsExisting: 'Email is same as existing email',
  unknown: 'Error sending verification email',
  userNotFound: 'User not found',
}
