import '@activesg/common/utilities/big-int/register.js'
import '@fontsource/ibm-plex-mono'
import 'inter-ui/inter.css'
import './turnstile.css'

import type { AppProps, AppType } from 'next/app'

import { useEffect } from 'react'

import Head from 'next/head'

import { Skeleton, Stack } from '@chakra-ui/react'
import { GrowthBook } from '@growthbook/growthbook-react'
import {
  RestrictedGovtMasthead,
  ThemeProvider,
} from '@opengovsg/design-system-react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { nanoid } from 'nanoid'

import { growthbookOpts, type AppFeatures } from '@activesg/common/libraries'
import { getBaseUrl } from '@activesg/common/utilities'
import { DEVICE_ID_KEY } from '@activesg/constants'
import { env } from '@activesg/env'
import {
  AppBanner,
  AppVersionModalBanner,
  EnvProvider,
  GrowthbookProvider,
  LoginStateProvider,
  Suspense,
} from '@activesg/ui/components'
import { useLocalStorage } from '@activesg/ui/hooks'
import { initMonitoring, ToastContainer } from '@activesg/ui/libraries'
import { theme } from '@activesg/ui/theme'
import { type NextPageWithLayout } from '@activesg/ui/types'

import { ErrorBoundary } from '~/components/ErrorBoundary'
import { HOME, SUSPENDED, TERMINATED } from '~/constants/routes'
import { UserOnboardingModalChecker } from '~/features/settings/components/UserOnboardingModal'
import { DefaultLayout } from '~/templates/layouts/DefaultLayout'
import { trpc } from '~/utils/trpc'

type AppPropsWithAuthAndLayout = AppProps & {
  Component: NextPageWithLayout
}

// We use process.env instead of env since we will capture this at build time.
if (process.env.NODE_ENV === 'production') {
  initMonitoring({ service: 'activesg-member' })
}

const gb = new GrowthBook<AppFeatures>(growthbookOpts)

void gb.init({
  streaming: true,
})

const MyApp = ((props: AppPropsWithAuthAndLayout) => {
  const indexable =
    env.NEXT_PUBLIC_ENVIRONMENT === 'production' &&
    ['/', HOME].includes(props.router.pathname)
  const isIsolatedPage = [SUSPENDED, TERMINATED].includes(props.router.pathname)
  const [deviceId, setDeviceId] = useLocalStorage<string | undefined>(
    DEVICE_ID_KEY,
    undefined,
  )
  useEffect(() => {
    if (!deviceId) {
      const random = nanoid()
      setDeviceId(random)
      void gb.setAttributes({
        ...gb.getAttributes(),
        deviceId: random,
      })
    } else {
      void gb.setAttributes({
        ...gb.getAttributes(),
        deviceId,
      })
    }
  }, [deviceId, setDeviceId])

  return (
    <>
      <Head>
        <title>{env.NEXT_PUBLIC_APP_NAME}</title>
        <meta
          content="Book sports facilities, join programmes, and purchase swim and gym passes with MyActiveSG+. With balloting for high demand peak hour slots and Singpass verification, there's more chances for everyone to book. Parents can link their children's accounts to book facilities, enrol them in programmes, and buy swim or gym passes for their children."
          name="description"
        />
        <link href="/favicon.ico" rel="icon" />
        <link
          href="/favicon-32x32.png"
          rel="icon"
          sizes="32x32"
          type="image/png"
        />

        <link
          href="/favicon-16x16.png"
          rel="icon"
          sizes="16x16"
          type="image/png"
        />
        <link
          href="/android-chrome-192x192.png"
          rel="icon"
          sizes="192x192"
          type="image/png"
        />
        <link
          href="/android-chrome-512x512.png"
          rel="icon"
          sizes="512x512"
          type="image/png"
        />
        <link
          href="/apple-touch-icon.png"
          rel="apple-touch-icon"
          sizes="180x180"
        />
        <meta content="ActiveSG" property="og:title" />
        <meta
          content={new URL('/og-image-1200x630.png', getBaseUrl()).href}
          property="og:image"
        />
        <meta content="https://activesg.gov.sg/" property="og:url" />
        <meta
          content="Book sports facilities, join programmes, and purchase swim and gym passes with MyActiveSG+. With balloting for high demand peak hour slots and Singpass verification, there's more chances for everyone to book. Parents can link their children's accounts to book facilities, enrol them in programmes, and buy swim or gym passes for their children."
          property="og:description"
        />
        <meta
          key="robots"
          content={`${indexable ? 'index' : 'noindex'},nofollow`}
          name="robots"
        />
      </Head>
      <EnvProvider env={env}>
        <LoginStateProvider>
          <GrowthbookProvider gb={gb} profile="PUBLIC">
            <ThemeProvider theme={theme}>
              <ErrorBoundary>
                <Suspense fallback={<Skeleton height="100vh" width="100vw" />}>
                  <Stack minH="$100vh" spacing={0}>
                    <RestrictedGovtMasthead />
                    <AppVersionModalBanner />
                    {!isIsolatedPage && <UserOnboardingModalChecker />}
                    <AppBanner />
                    <ChildWithLayout {...props} />
                    {process.env.NODE_ENV !== 'production' && (
                      <ReactQueryDevtools initialIsOpen={false} />
                    )}
                  </Stack>
                </Suspense>
              </ErrorBoundary>
            </ThemeProvider>
          </GrowthbookProvider>
        </LoginStateProvider>
      </EnvProvider>
      <ToastContainer />
    </>
  )
}) as AppType

// This is needed so suspense will be triggered for anything within the LayoutComponents which uses useSuspenseQuery
const ChildWithLayout = ({
  Component,
  pageProps,
}: AppPropsWithAuthAndLayout) => {
  const getLayout = Component.getLayout ?? DefaultLayout

  return <>{getLayout(<Component {...pageProps} />)}</>
}

export default trpc.withTRPC(MyApp)
