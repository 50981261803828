import { forwardRef, useCallback, useEffect, useState } from 'react';
import { NumberInput, NumberInputField, } from '@chakra-ui/react';
import { POSTGRES_MAX_SAFE_INTEGER } from '@activesg/constants';
// Acceptable inputs, allows intermediate values like `1.`
const INTEGER_REGEX = /^[0-9]*$/;
const DECIMAL_REGEX = /^[0-9]*(\.[0-9]{0,2})*$/; // max 2dp
const isValidInput = (v, integerOnly) => {
    const valid = integerOnly ? INTEGER_REGEX.test(v) : DECIMAL_REGEX.test(v);
    return valid;
};
// Well-formed integer or decimal, does not allow intermediate values
const VALID_INTEGER_NUMBER = /^[0-9]+$/;
const VALID_DECIMAL_NUMBER = /^[0-9]+\.[0-9]{1,2}$/;
const isValidNumber = (v) => {
    const notNan = !isNaN(Number(v));
    const validInt = VALID_INTEGER_NUMBER.test(v);
    const validDec = VALID_DECIMAL_NUMBER.test(v);
    return validInt || validDec || notNan;
};
const getInitialValue = (initialValue) => {
    if (initialValue.value === undefined || initialValue.value === null) {
        return '';
    }
    if (initialValue.zodType === 'string') {
        return initialValue.value;
    }
    return initialValue.value.toString();
};
/** COPY PASTE FROM CARE360 PositiveNumberInput */
// Extend Chakra's NumberInput for some extra validation
export const PositiveNumberInput = forwardRef(({ initialValue, onChange: onChangeProp, integerOnly = false, watchedValue, placeholder, fieldProps, ...rest }, ref) => {
    const [value, setValue] = useState(getInitialValue(initialValue));
    useEffect(() => {
        if (initialValue.value === null || initialValue.value === undefined) {
            setValue('');
        }
    }, [initialValue.value]);
    useEffect(() => {
        if (watchedValue !== undefined) {
            setValue(typeof watchedValue === 'number'
                ? watchedValue.toString()
                : watchedValue);
        }
    }, [watchedValue]);
    const onChange = useCallback((newValue) => {
        if (!isValidInput(newValue, integerOnly))
            return;
        setValue(newValue);
        if (newValue === '') {
            // set to undefined so `optional()` zod validation can pass, and non-optional validations will be set to null in database if needed
            onChangeProp(undefined);
            return;
        }
        // Set if number is valid -- must be a well-formed integer or decimal
        if (isValidNumber(newValue)) {
            if (initialValue.zodType === 'string') {
                onChangeProp(newValue);
            }
            else {
                onChangeProp(Number(newValue));
            }
        }
    }, [initialValue.zodType, integerOnly, onChangeProp]);
    return (<NumberInput ref={ref} clampValueOnBlur={true} max={POSTGRES_MAX_SAFE_INTEGER} value={value} onChange={(valueAsString) => onChange(valueAsString)} {...rest}>
        <NumberInputField placeholder={placeholder} {...fieldProps}/>
      </NumberInput>);
});
PositiveNumberInput.displayName = 'PositiveNumberInput';
