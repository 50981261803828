import { Tab, useMultiStyleConfig, useTabsContext } from '@chakra-ui/react';
import { merge } from 'lodash-es';
export const LowercaseTab = (props) => {
    const { orientation } = useTabsContext();
    const styles = useMultiStyleConfig('Tabs', { orientation });
    const lowercaseStyles = merge(styles, {
        tab: {
            textTransform: 'none',
            _selected: { textTransform: 'none' },
        },
    }, props.sx ?? {});
    return <Tab sx={lowercaseStyles.tab} {...props}/>;
};
