import { useFeatureIsOn as _useFeatureIsOn, useFeatureValue as _useFeatureValue, } from '@growthbook/growthbook-react';
import { APP_FEATURES } from '@activesg/common/libraries';
import { env } from '@activesg/env';
export const useFeatureValue = (id, fallback) => {
    const value = _useFeatureValue(id, fallback);
    const schema = APP_FEATURES[id];
    const parse = schema.safeParse(value);
    if (!parse.success) {
        console.error(`Feature flag value for ${id} is invalid: ${parse.error.message}`);
        return fallback;
    }
    return parse.data;
};
export const useFeatureIsOn = (id) => {
    const isDev = env.NEXT_PUBLIC_ENVIRONMENT === 'development';
    if (!isDev) {
        return _useFeatureIsOn(id);
    }
    if (id === 'quota-limit-include-children') {
        return false;
    }
    return true;
};
