import { useLoginState } from '@activesg/ui/hooks'

import { UserOnboardingModal } from './UserOnboardingModal'
import { UserOnboardingProvider } from './UserOnboardingModalContext'

export const UserOnboardingModalChecker = () => {
  const { hasLoginStateFlag } = useLoginState()
  if (!hasLoginStateFlag) {
    return null
  }

  return (
    <UserOnboardingProvider>
      <UserOnboardingModal />
    </UserOnboardingProvider>
  )
}
