export const safeSchemaJsonParse = (schema, jsonString, 
// eslint-disable-next-line no-restricted-syntax
parse = JSON.parse) => {
    try {
        const parsed = parse(jsonString);
        return schema.safeParse(parsed);
    }
    catch (e) {
        if (e instanceof Error) {
            return { success: false, error: e };
        }
        return { success: false, error: new Error(`Unknown JSON parse error`) };
    }
};
