export const LINKED_ACCOUNTS_ROUTES = {
    root: () => '/linked-accounts',
    add: () => ({
        root: () => `${LINKED_ACCOUNTS_ROUTES.root()}/add`,
        child: () => `${LINKED_ACCOUNTS_ROUTES.add().root()}/child`,
    }),
    errors: () => ({
        loginMismatch: () => `${LINKED_ACCOUNTS_ROUTES.add().root()}/login-mismatch`,
    }),
    // Not only for children but any linked account
    child: (childId) => `${LINKED_ACCOUNTS_ROUTES.root()}/child/${childId}`,
    review: (requestorId) => ({
        root: () => `${LINKED_ACCOUNTS_ROUTES.root()}/review/${requestorId}`,
    }),
};
