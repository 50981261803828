import { env } from 'process'

import { useAtomValue } from 'jotai'

import { useFeatureIsOn } from '@activesg/ui/libraries'

import { imitateUserAtom } from './imitate-user.atom'

export const useIsImitatingUser = () => {
  const imitateUserId = useAtomValue(imitateUserAtom)

  const isImitatingUser =
    useFeatureIsOn('imitate-member') &&
    env.NEXT_PUBLIC_ENVIRONMENT !== 'production' &&
    !!imitateUserId

  return isImitatingUser
}
