const plugin = (_option, dayjsClass, _dayjsFactory) => {
    // @ts-expect-error #TS2339
    dayjsClass.prototype.toLocalDateOnly = function () {
        return new Date(this.format('YYYY-MM-DD'));
    };
    // @ts-expect-error #TS2339
    dayjsClass.prototype.toLocalDateOnlyString = function () {
        return this.format('YYYY-MM-DD');
    };
};
export default plugin;
