export const ACTIVE_SG_PROGRAMME_TYPES = {
    ACADEMIES_AND_CLUBS: 'ACADEMIES_AND_CLUBS',
    ACTIVE_HEALTH: 'ACTIVE_HEALTH',
    LEARN_TO_PLAY: 'LEARN_TO_PLAY',
    SPORTS_EDUCATION_PROGRAMME: 'SPORTS_EDUCATION_PROGRAMME',
    SPORTS_CARES: 'SPORTS_CARES',
};
export const PROGRAMME_INTENSITY_LEVEL = {
    Low: 'Low',
    Moderate: 'Moderate',
    Vigorous: 'Vigorous',
};
export const isProgrammeIntensityLevel = (value) => {
    return Object.keys(PROGRAMME_INTENSITY_LEVEL).includes(value);
};
export const PROGRAMME_STATUS = {
    ACTIVE: 'ACTIVE',
    ENDED: 'ENDED',
    CANCELLED: 'CANCELLED',
    ALL: 'ALL',
};
