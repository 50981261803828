import { useMemo, useRef } from 'react';
import { debounce } from 'lodash-es';
export const useScrollToRef = ({ enabled }) => {
    const scrollRef = useRef(null);
    const rescroll = useMemo(() => debounce(() => {
        if (enabled) {
            setTimeout(() => scrollRef.current?.scrollIntoView({ behavior: 'smooth' }), 100);
        }
    }, 500, { leading: true, trailing: false }), [enabled]);
    return {
        scrollRef,
        rescroll,
    };
};
