import { useCallback, useEffect, useMemo } from 'react'

import Router from 'next/router'

import { useGrowthBook } from '@growthbook/growthbook-react'
import { useQueryClient } from '@tanstack/react-query'

import { displayCurrency } from '@activesg/common/utilities'
import { useLoginState } from '@activesg/ui/hooks'
import { setMonitoringUserContext } from '@activesg/ui/libraries'

import { trpc } from '~/utils/trpc'

export const useMe = () => {
  const [me] = trpc.me.get.useSuspenseQuery()
  const queryClient = useQueryClient()
  const gb = useGrowthBook()

  useEffect(() => {
    void gb.setAttributes({
      ...gb.getAttributes(),
      id: me.id,
    })
  }, [gb, me])

  const myAsgDollars = useMemo(
    () => displayCurrency(me.credits?.balanceInCents ?? '0'),
    [me],
  )

  const { removeLoginStateFlag } = useLoginState()

  const logoutMutation = trpc.auth.logout.useMutation()

  const logout = useCallback(
    (redirectToSignIn = true) => {
      return logoutMutation.mutate(undefined, {
        onSuccess: () => {
          removeLoginStateFlag()
          queryClient.removeQueries()
          if (redirectToSignIn) {
            void Router.push('/sign-in')
          }
        },
      })
    },
    [logoutMutation, removeLoginStateFlag, queryClient],
  )

  setMonitoringUserContext({ id: me.id })

  return { me, myAsgDollars, logout }
}
