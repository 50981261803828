import { ld } from './dates.js';
export function convertToStartAndEndDateTimes(object) {
    const { date, startTime, endTime, ...rest } = object;
    const startOfDay = ld.startOfDay(date);
    return {
        ...rest,
        startDateTime: new Date(startOfDay.valueOf() + startTime),
        endDateTime: new Date(startOfDay.valueOf() + endTime),
    };
}
export const mergeTimeslots = (timeslots) => {
    const sortedTimeslots = timeslots.sort((a, b) => {
        return a.startDateTime.valueOf() - b.startDateTime.valueOf();
    });
    const result = [];
    let currentTimeslot = undefined;
    for (const timeslot of sortedTimeslots) {
        if (!currentTimeslot) {
            currentTimeslot = timeslot;
            continue;
        }
        if (timeslot.startDateTime.valueOf() === currentTimeslot.endDateTime.valueOf()) {
            currentTimeslot = {
                startDateTime: currentTimeslot.startDateTime,
                endDateTime: timeslot.endDateTime,
            };
        }
        else {
            result.push(currentTimeslot);
            currentTimeslot = timeslot;
        }
    }
    if (currentTimeslot) {
        result.push(currentTimeslot);
    }
    return result;
};
